import React from 'react'
import PageHeader from '../../components/PageHeader'
import InvoiceReportTable from './InvoiceReportTable'


const InvoiceReport = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Invoice" dateLabel="Invoice Date"/>
      <InvoiceReportTable />
    </div>
  )
}

export default InvoiceReport