import React, { useState } from 'react'
import MonthlyTransactionTable from './MonthlyTransactionTable'
import PageHeaderMonthlyInventory from 'components/PageHeaderMonthlyInventory'
import moment from 'moment'

const MonthlyInventoryTransaction = () => {
  const [dateRange, setDateRange] = useState({startDate: moment().startOf("month"), endDate: moment().endOf("month")})

  const valueChange = (start, end) => {
    setDateRange({startDate:start, endDate:end})
  }

  return (
    <div className="dashboard-container">
      <PageHeaderMonthlyInventory title="Reports:- Monthly Inventory Transaction" onCalendarChange = {valueChange}/>
      <MonthlyTransactionTable dateValue = {dateRange}/>
    </div>
  )
}

export default MonthlyInventoryTransaction