import React, { useState } from 'react'
import PageHeader from '../../components/PageHeader'
import dayjs from 'dayjs'
import InventoryTransactionTable from './InventoryTransactionTable'

const InventoryTransaction = () => {
  const [eventData, setEventData] = useState(dayjs() )

  const handleDateChange = (e) => {
    const statusValue = e.$d
    const dateDaoily = statusValue.toISOString().substring(0, 10)
    setEventData(dateDaoily)
  }

  return (
    <div className="dashboard-container">
      <PageHeader title="Reports:- Current Inventory Transaction" hideDateRange = {true} handleDateChange={handleDateChange}/>
      <InventoryTransactionTable eventData={eventData}/>
    </div>
  )
}

export default InventoryTransaction