import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardDate } from "../../features/dashboard/dashboardSlice";
import { saveUserSetting } from "../../features/user/userSlice";
import CloseIcon from "@mui/icons-material/Close";
import {
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

const PageHeaderMonthlyInventory = (props) => {
  const dispatch = useDispatch();
  const [dailyValue, setDailyValue] = useState(dayjs());
  const [inventoryDate, setInventoryDate]  = useState({startDate: moment().startOf("month"), endDate: moment().endOf("month")});
  const label =
  inventoryDate.startDate.format("MMMM D, YYYY") + " - " + inventoryDate.endDate.format("MMMM D, YYYY");

  const { userSetting, userSettingData, user } = useSelector(
    (state) => state.user
  );

  const [loc, setLoc] = useState(
    userSetting && userSetting.length && userSetting[0].currWarehouse
  );

  const [customer, setCustomer] = useState(
    userSetting && userSetting.length && userSetting[0].currCustomer
  );

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleCallback = (start, end) => {
    setInventoryDate({ startDate: start, endDate: end });
    props.onCalendarChange(start, end)
  };
  

  const handleLocationChange = (e) => {
    setLoc(e.target.value);
    dispatch(
      saveUserSetting({
        userId: user.Id,
        token: user.token,
        currCustomer: userSetting[0].currCustomer,
        currWarehouse: e.target.value,
      })
    );
  };

  const handleCustomerChange = (e) => {
    setCustomer(e.target.value);
    dispatch(
      saveUserSetting({
        userId: user.Id,
        token: user.token,
        currCustomer: e.target.value,
        currWarehouse: userSetting[0].currWarehouse,
      })
    );
  };

  const handleDailyChange = async (event) => {
    if (event) {
      props.handleDateChange(event);
    }
  };

  return (
    <>
      <div className="page-header-container">
        <div className="page-title">{props.title}</div>
        <div className="page-header-right">
          {!props.hideCalendar && (
            <div className="page-header-calendar">
              {!props.hideDateRange && (
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(0).startOf("month").toDate(),
                    endDate: moment(0).endOf("month").toDate(),
                    ranges: {
                      "January": [
                        moment().month(0).startOf("month").toDate(),
                        moment().month(0).endOf("month").toDate(),
                      ],
                     "February": [
                        moment().month(1).startOf("month").toDate(),
                        moment().month(1).endOf("month").toDate(),
                      ],
                      "March": [
                        moment().month(2).startOf("month").toDate(),
                        moment().month(2).endOf("month").toDate(),
                      ],
                      "April": [
                        moment().month(3).startOf("month").toDate(),
                        moment().month(3).endOf("month").toDate(),
                      ],
                      "May": [
                        moment().month(4).startOf("month").toDate(),
                        moment().month(4).endOf("month").toDate(),
                      ],
                      "June": [
                        moment().month(5).startOf("month").toDate(),
                        moment().month(5).endOf("month").toDate(),
                      ],
                      "July": [
                        moment().month(6).startOf("month").toDate(),
                        moment().month(6).endOf("month").toDate(),
                      ],
                      "August": [
                        moment().month(7).startOf("month").toDate(),
                        moment().month(7).endOf("month").toDate(),
                      ],
                      "September": [
                        moment().month(8).startOf("month").toDate(),
                        moment().month(8).endOf("month").toDate(),
                      ],
                      "October": [
                        moment().month(9).startOf("month").toDate(),
                        moment().month(9).endOf("month").toDate(),
                      ],
                      "November": [
                        moment().month(10).startOf("month").toDate(),
                        moment().month(10).endOf("month").toDate(),
                      ],
                      "December": [
                        moment().month(11).startOf("month").toDate(),
                        moment().month(11).endOf("month").toDate(),
                      ],
                    },
                    showCustomRangeLabel: false,
                    alwaysShowCalendars: true
                  }}
                  onCallback={handleCallback}
                >
                  <div id="reportrange" className="box-with-icon">
                    <span>{label}</span>
                    <CalendarMonthOutlinedIcon />
                  </div>
                </DateRangePicker>
              )}

              {props.hideDateRange && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label=""
                    format="YYYY-MM-DD"
                    value={dailyValue}
                    className="ml-8"
                    onAccept={(newValue) => setDailyValue(newValue)}
                    onChange={(newDailyValue) =>
                      handleDailyChange(newDailyValue)
                    }
                    slotProps={{ textField: { size: "small" } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "6px 12px",
                        border: "1px solid #d1d4e2",
                      },
                    }}
                    slots={{ openPickerIcon: CalendarMonthOutlinedIcon }}
                  />
                </LocalizationProvider>
              )}
            </div>
          )}
          {user.userType === "Admin" && (
            <div className="page-header-filter">
              <div className="box-with-icon" onClick={toggleDrawer(true)}>
                <span>Fiter By</span>
                <FilterListOutlinedIcon />
              </div>
            </div>
          )}
        </div>
      </div>
      {userSetting && userSetting.length && (
        <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
          <div className="filter-drawer">
            <div className="filter-header-wrap">
              <span>Filters By</span>
              <IconButton aria-label="close" onClick={toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="filter-opt-wrap">
              <div className="filter-opt">
                <FormControl>
                  <FormLabel id="warehouse-radio-group-label">
                    Warehouse
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="warehouse-radio-group-label"
                    defaultValue={userSetting[0].currWarehouse}
                    name="warehouse-radio-buttons-group"
                    onChange={handleLocationChange}
                    value={loc}
                  >
                    {userSettingData["Table1"].map((item) => {
                      return (
                        <FormControlLabel
                          value={item.whseID}
                          control={<Radio />}
                          label={item.warehouseName}
                          key={item.whseID}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="filter-opt">
                <FormControl>
                  <FormLabel id="facilities-radio-group-label">
                    Customer
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="facilities-radio-group-label"
                    defaultValue={userSetting[0].currCustomer}
                    name="facilities-radio-buttons-group"
                    value={customer}
                    onChange={handleCustomerChange}
                  >
                    {userSettingData["Table"].map((item) => {
                      return (
                        <FormControlLabel
                          value={item.custID}
                          control={<Radio />}
                          label={item.customerName}
                          key={item.custID}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
              {/* <div className="filter-opt">
                <FormControl>
                  <FormLabel id="facilities-radio-group-label">
                    My Prefrences
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="facilities-radio-group-label"
                    defaultValue="DISTRIVALTO"
                    name="facilities-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="DateWiseShipment"
                      control={<Radio />}
                      label="Date Wise Shipment"
                    />
                    <FormControlLabel
                      value="dateWiseShipmentgen"
                      control={<Radio />}
                      label="Date Wise Shipment Gen, LLC"
                    />
                    <FormControlLabel
                      value="asnstatus"
                      control={<Radio />}
                      label="ASN Status"
                    />
                  </RadioGroup>
                </FormControl>
              </div> */}
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default PageHeaderMonthlyInventory;
