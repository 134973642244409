import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";

const columns = [
  {
    field: "OrderNumber",
    headerClassName: "headingbg-color",
    headerName: "OrderNumber",
  },
  {
    field: "ShipDate",
    width: 120,
    headerClassName: "headingbg-color",
    headerName: "ShipDate",
  },
  {
    field: "CustPOnumber",
    headerClassName: "headingbg-color",
    headerName: "CustPOnumber",
    width: 200,
  },
  {
    field: "ShipTo",
    headerClassName: "headingbg-color",
    headerName: "ShipTo",
  },
  {
    field: "BillTo",
    headerClassName: "headingbg-color",
    headerName: "BillTo",
  },

  {
    field: "BLNumber",
    headerClassName: "headingbg-color",
    headerName: "BLNumber",
  },
  {
    field: "LoadNumber",
    headerClassName: "headingbg-color",
    headerName: "LoadNumber",
  },
  {
    field: "TRAILERKEY",
    headerClassName: "headingbg-color",
    headerName: "TRAILERKEY",
  },
  {
    field: "TrailerNumber",
    headerClassName: "headingbg-color",
    headerName: "TrailerNumber",
  },
  {
    field: "TrailerType",
    headerClassName: "headingbg-color",
    headerName: "TrailerType",
  },

  {
    field: "ItemNumber",
    headerClassName: "headingbg-color",
    headerName: "ItemNumber",
  },
  {
    field: "Description",
    width: 250,
    headerClassName: "headingbg-color",
    headerName: "Description",
  },
];

const ShipmentReportDateWiseTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      orderNo: "all",
      poNo: "all",
      shipTo: "all",
      billTo: "all",
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getRprt_dateWiseShipmentDtls",
      payload,
      headers
    );
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    OrderNumber: c.OrderNumber,
    ShipDate: moment(c.ShipDate).format("YYYY-MM-DD"),
    CustPOnumber: c.CustPOnumber,
    ShipTo: c.ShipTo,
    BillTo: c.BillTo,
    BLNumber: c.BLNumber,
    LoadNumber: c.LoadNumber,
    TRAILERKEY: c.TRAILERKEY,
    TrailerNumber: c.TrailerNumber,
    TrailerType: c.TrailerType,
    ItemNumber: c.ItemNumber,
    Description: c.Description,
  }));
  return (
    <div
      style={{
        height: 700,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
        loading={isLoading}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        
        columns={columns}
        rows={rows}
        sx={{ flex: 1, "& .MuiButton-text": { fontSize:  "14px"} }}
        //   style={{alignItems: 'center'}}
      ></DataGridPremium>
    </div>
  );
};

export default ShipmentReportDateWiseTable;
