import React from 'react'
import PageHeader from '../../components/PageHeader'
import AsnTable from './AsnTable'

const Asn = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Received Summary" dateLabel="Receipt Date"/>
      <AsnTable />
    </div>
  )
}

export default Asn