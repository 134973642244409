import { Button, FormLabel, Grid, MenuItem, OutlinedInput, Select, Stack } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { apiEndPoint } from "services";
import { postRequest } from "services/api";

const columns = [
  { field: 'InvoiceNum', headerClassName: "headingbg-color", headerName: 'Invoice Num', width: 200 },
  { field: 'InvoiceDate',         
     headerClassName: "headingbg-color", headerName: 'Invoice Date', width: 200 },
  { field: 'InvoiceAmt', headerClassName: "headingbg-color", headerName: 'Invoice Amt', width: 200 },
  // { field: 'INV_CURR_CODE', width: 120, headerClassName: "headingbg-color", headerName: 'Invoice Currency Code' },
  { field: 'DueDate', headerClassName: "headingbg-color", headerName: 'Due Date', width: 180 },
  // { field: 'InvoiceType', headerClassName: "headingbg-color", headerName: 'Invoice Type', width: 165 },
  // { field: 'InvoiceStatus', headerClassName: "headingbg-color", headerName: 'Invoice Status', width: 150 },
  // { field: 'CustomerCode', headerClassName: "headingbg-color", headerName: 'Customer Code', width: 150 },
  { field: 'Warehouse', headerClassName: "headingbg-color", headerName: 'Warehouse', width: 150 },
  { field: 'qbInvoiceAmt', headerClassName: "headingbg-color", headerName: 'Qb Invoice Amount', width: 150 },
  { field: 'qbBalanceAmt', headerClassName: "headingbg-color", headerName: 'Qb Balance Amount', width: 150 },
  { field: 'paymentStatus', headerClassName: "headingbg-color", headerName: 'Payment Status', width: 150 },
  { field: 'overDue30', headerClassName: "headingbg-color", headerName: 'Overdue 30', width: 150 },
  { field: 'overDue60', headerClassName: "headingbg-color", headerName: 'Overdue 60', width: 150 },
  { field: 'overDue90', headerClassName: "headingbg-color", headerName: 'Overdue 90', width: 150 },
  {
    field: 'SummaryInvFile',
    renderCell: (params) => {
      
        if(params.value) {
          return <a href={params.value ? `${apiEndPoint}/${params.value}`: '#'} target="_blank" rel="noreferrer">View Invoice Summary</a>
        } else {
          return params.value && params.value.toString();
        }
      
    },
    headerClassName: "headingbg-color", headerName: 'Invoice Summary'
  },
  {
    field: 'DetailedInvFile',
    renderCell: (params) => 
      {
        if(params.value) {
          return <a href={params.value ? `${apiEndPoint}/${params.value}`: '#'} target="_blank" rel="noreferrer">View Invoice Detail</a>
        } else {
          return params.value && params.value.toString();
        }
      }
      
    ,
    headerClassName: "headingbg-color", headerName: 'Invoice Detail'
  },
]

const InvoiceReportTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [statusMenu, setStatusMenu] = useState([]);
  const [paymentMenu, setPaymentMenu] = useState([])
  const [dueDate, setDueDate] = useState('all');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [payStatus, setPayStatus] = useState('Due');
  const [clearFormData, setClearFormData] = useState(false);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      from_invoiceDt: startDate.format("YYYY-MM-DD"),
      to_invoiceDt: endDate.format("YYYY-MM-DD"),
      invoiceNum:invoiceNumber ? invoiceNumber: "all",
      dueDays: dueDate? dueDate: "all",
      payStatus: payStatus ? payStatus : 'all'
    };
    const response = await postRequest(
      "user/getRprt_invoiceDtls2",
      payload,
      headers
    );
    setClearFormData(false);
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c, index) => ({
    id: index,
    InvoiceNum: c.InvoiceNum,
    InvoiceDate: c.InvoiceDate,
    InvoiceAmt: "$" + c.InvoiceAmt,
    INV_CURR_CODE: c.INV_CURR_CODE,
    DueDate: c.DueDate,
    BillTo: c.BillTo,
    InvoiceType: c.InvoiceType,
    InvoiceStatus: c.InvoiceStatus,
    CustomerCode: c.CustomerCode,
    Warehouse:c.Warehouse,
    qbInvoiceAmt: c.qbInvoiceAmt,
    qbBalanceAmt:c.qbBalanceAmt,
    paymentStatus:c.paymentStatus,
    overDue30: c.overDue30,
    overDue60: c.overDue60,
    overDue90: c.overDue90,
    SummaryInvFile: c.SummaryInvFile,
    DetailedInvFile:c.DetailedInvFile,
    
  }));

  const getStatus = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      masterListFor: 'invoiceDueDays'
    };
    const response = await postRequest(
      "user/Get_MasListVals",
      payload,
      headers
    );

    setStatusMenu(response.data.Payload.Table)
  }

  const getPaymentStatus = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      masterListFor: 'invoicePayStatus'
    };
    const response = await postRequest(
      "user/Get_MasListVals",
      payload,
      headers
    );

    setPaymentMenu(response.data.Payload.Table)
  }

  useEffect(() => {
    getStatus();
    getPaymentStatus();
  }, [])

  const handleSearch = () => {
    fetchTableData()
  }

  const clearForm = () => {
    setDueDate('all')
    setInvoiceNumber('');
    setPayStatus('Due')
    setClearFormData(true);
  }

  useEffect(() => {
    if(clearFormData) {
      fetchTableData()
    }
  }, [clearFormData])

  return (
    <>
      <div>
        <Grid container spacing={2}>
          <Grid item xs= {12} md= {4}>
            <FormLabel htmlFor="dueDate" sx={{fontSize:"0.9rem"}}>
              Due Date
            </FormLabel>
            <div>
            <Select
              SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
              labelId="dueDate"
              id="dueDate"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              fullWidth
              inputProps={{
                style: {
                  maxHeight: "0.6rem",
                },
              }}
            >
               <MenuItem value="all">All</MenuItem>
              {statusMenu.map( item => <MenuItem value={item.text}>{item.text}</MenuItem>)}
            </Select>
            </div>
          </Grid>
          <Grid item xs= {12} md= {4}>
            <FormLabel htmlFor="invoice-number" sx={{fontSize:"0.9rem"}}>
              Invoice Number
            </FormLabel>
            <div>
            <OutlinedInput
              name="invoice-number"
              placeholder="Enter Invoice Number"
              fullWidth
              inputProps={{
                style: {
                  height: "0.3rem",
                },
              }}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              value={invoiceNumber}
            />
            </div>
          </Grid>
          <Grid item xs= {12} md= {4}>
            <FormLabel htmlFor="dueDate" sx={{fontSize:"0.9rem"}}>
              Pay Status
            </FormLabel>
            <div>
            <Select
              SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
              labelId="payStatus"
              id="payStatus"
              value={payStatus}
              onChange={(e) => setPayStatus(e.target.value)}
              fullWidth
              inputProps={{
                style: {
                  maxHeight: "0.6rem",
                },
              }}
            >
              <MenuItem value="all">All</MenuItem>
             {paymentMenu.map( item => <MenuItem value={item.value}>{item.text}</MenuItem>)}
            </Select>
            </div>
          </Grid>

         <Grid item xs= {12} md= {12}>
          <Stack direction="row" spacing={2}>
            <Button color="grey"  variant="contained" onClick={clearForm} size="small">Reset</Button>
            <Button variant="contained" onClick={handleSearch} size="small">
              Apply Filter
            </Button>
          </Stack>
          </Grid>

        </Grid>
      </div>
      <div
        style={{
          height: 490,
          width: "100%",
          marginTop: "20px",
          background: "#fff",
        }}
      >
        <DataGridPremium
          loading={isLoading}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          columns={columns}
          rows={rows}
          sx={{ flex: 1, "& .MuiButton-text": { fontSize:  "14px"} }}
        ></DataGridPremium>
      </div>
    </>
  );
};

export default InvoiceReportTable;
