import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { postRequest } from "services/api";
import "../../css/pages/bolReports.css";
import PDFViewer from "components/PDFViewer";
import { apiEndPoint } from "services";



const columns = [
  {
    field: "OrderNumber",
    width: 120,
    headerClassName: "headingbg-color",
    headerName: "IGD Order",
  },
  {
    field: "ExternalOrderNo",
    width: 120,
    headerClassName: "headingbg-color",
    headerName: "Customer Order",
  },
  {
    field: "BuyerPOnumber",
    headerClassName: "headingbg-color",
    headerName: "Customer PO Number",
  },
  {
    field: "ActualShipDate",
    width: 120,
    headerClassName: "headingbg-color",
    headerName: "Actual Ship Date",
  },
  {
    field: "BLNumber",
    width: 150,
    renderCell: (params) => {
      if (params.row.signedBOLFile || params.row.sourceBOLFile) {
        return <Link href="#">{params.value && params.value.toString()}</Link>;
      } else {
        return params.value && params.value.toString();
      }
    },
    headerClassName: "headingbg-color",
    headerName: "BOL Number",
  },
  {
    field: "BLStatus",
    headerClassName: "headingbg-color",
    headerName: "BOL Status",
  },
  {
    field: "OrderType",
    headerClassName: "headingbg-color",
    headerName: "Order Type",
  },

  {
    field: "BillTo",
    renderCell: (params) => <Link href="#">{params.value.toString()}</Link>,
    headerClassName: "headingbg-color",
    headerName: "Bill To",
  },
  {
    field: "ShipTo",
    renderCell: (params) => <Link href="#">{params.value.toString()}</Link>,
    headerClassName: "headingbg-color",
    headerName: "Ship To",
  },

  {
    field: "CarrierCode",
    headerClassName: "headingbg-color",
    headerName: "SCAC",
  },
  {
    field: "CarrierName",
    headerClassName: "headingbg-color",
    headerName: "Carrier Name",
  },
  {
    field: "FreightPaymentType",
    headerClassName: "headingbg-color",
    headerName: "Freight Payment Type",
  },

  {
    field: "Tracking_PROnumber",
    headerClassName: "headingbg-color",
    headerName: "Tracking/PRO",
  },
  {
    field: "SealNumber",
    width: 250,
    headerClassName: "headingbg-color",
    headerName: "Seal Number",
  },
  {
    field: "isBOLSignedRender",
    width: 50,
    headerClassName: "headingbg-color",
    headerName: "BOL Signed",
  },
  // { field: 'signedBOLFile', width: 200, headerClassName: "headingbg-color", headerName: 'Signed BOL File' },
  // { field: 'sourceBOLFile', width: 200, headerClassName: "headingbg-color", headerName: 'source BOL File' }
];

const BOLReportsTable = () => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderNo, setOrderNo] = useState("");
  const [isSigned, setIsSigned] = useState(0);
  const [signedFileName, setsignedFileName] = useState("");
  const [orderDataBillShipTo, setOrderDataBillShipTo] = useState([]);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      carrier: "all",
      status: "all",
    };
    const response = await postRequest(
      "user/getRprt_billOfLadingDtls",
      payload,
      headers
    );
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c, index) => ({
    id: index,
    OrderNumber: c.OrderNumber,
    BLNumber: c.BLNumber,
    BLStatus: c.BLStatus,
    OrderType: c.OrderType,
    BillTo: c.BillTo,
    ShipTo: c.ShipTo,
    CarrierCode: c.CarrierCode,
    CarrierName: c.CarrierName,
    FreightPaymentType: c.FreightPaymentType,
    Tracking_PROnumber: c.Tracking_PROnumber,
    SealNumber: c.SealNumber,
    isBOLSigned: c.isBOLSigned,
    isBOLSignedRender: c.isBOLSigned === 0 ? "No" : "Yes",
    signedBOLFile: c.signedBOLFile,
    sourceBOLFile: c.sourceBOLFile,
    ExternalOrderNo: c.ExternalOrderNo,
    PurchaseOrder: c.PurchaseOrder,
    ActualShipDate: c.ActualShipDate,
    BuyerPOnumber: c.BuyerPOnumber
  }));

  const getBillToShipToDetails = async (params) => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      orderNo: params,
    };
    const response = await postRequest(
      "user/GetOrderBillToShipToDtls",
      payload,
      headers
    );
    setOrderDataBillShipTo(response?.data?.Payload);
  };

  const handleCellSelectionModelChange = useCallback((newModel) => {
    Object.entries(newModel).forEach(([id, fields]) => {
      if (fields.BillTo || fields.ShipTo) {
        const updatedRow = apiRef.current.getRow(id);
        getBillToShipToDetails(updatedRow.OrderNumber);
        setOpenPopUp(true);
      } else if (fields.BLNumber) {
        const updatedRow = apiRef.current.getRow(id);
        setOrderNo(updatedRow.OrderNumber);
        setIsSigned(updatedRow.isBOLSigned);
        const filepath = `${apiEndPoint}/`;
        setsignedFileName(filepath + updatedRow.signedBOLFile);
        if (updatedRow.isBOLSigned) {
          setOpen(true);
        } else {
          if (updatedRow.sourceBOLFile) {
            setsignedFileName(filepath + updatedRow.sourceBOLFile);
            setOpen(true);
          }
        }
      } else {
        return;
      }
    });

    // setCellSelectionModel(newModel);
  }, []);

  return (
    <div
      style={{
        height: 700,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
        loading={isLoading}
        slots={{ toolbar: GridToolbar }}
        apiRef={apiRef}
        //cellSelectionModel={cellSelectionModel}
        onCellSelectionModelChange={handleCellSelectionModelChange}
        cellSelection={true}
        //onRowClick={handlerRowClick}
        columns={columns}
        rows={rows}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{ "& .MuiButton-text": { fontSize:  "14px"} }}
      ></DataGridPremium>

      {open && (
        <Dialog
          id="signDialog"
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "left",
          }}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Order No: {orderNo}
          </DialogTitle>
          <DialogContent>
            <PDFViewer
              fileName={signedFileName}
              action={() => {}}
              isSigned={isSigned}
              showSign={false}
              orderNo={orderNo}
            />
          </DialogContent>
        </Dialog>
      )}

      {openPopUp && (
        <Dialog
          open={openPopUp}
          onClose={() => setOpenPopUp(false)}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Bill To / Ship To Details:{" "}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: 800, height: 550 }} className="bolReportTable">
              {orderDataBillShipTo?.map((s) => {
                return (
                  <>
                    <table style={{ height: "60%", width: "100%" }}>
                      <th>Bill To Details :</th>
                      <tr>
                        <td className="font-bold">Order Number</td>
                        <td>{s.OrderNumber}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo Name</td>
                        <td>{s.BillTo_Name}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">BillTo Address</td>
                        <td>{s.BillTo_Address}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo City</td>
                        <td>{s.BillTo_City}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">BillTo State</td>
                        <td>{s.BillTo_State}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo Country</td>
                        <td>{s.BillTo_Country}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">BillTo ZipCode</td>
                        <td>{s.BillTo_ZipCode}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo PhoneNo</td>
                        <td>{s.BillTo_PhoneNo}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">BillTo Contact</td>
                        <td>{s.BillTo_Contact}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo VAT</td>
                        <td>{s.BillTo_VAT}</td>
                      </tr>
                      <div></div>
                      <div></div>
                      <div></div>
                    </table>
                    <table style={{ height: "60%", width: "100%" }}>
                      <th>Ship To Details :</th>

                      <tr>
                        <td className="font-bold">Order Number</td>
                        <td>{s.OrderNumber}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo Name</td>
                        <td>{s.ShipTo_Name}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo Address</td>
                        <td>{s.ShipTo_Address}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo City</td>
                        <td>{s.ShipTo_City}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo State</td>
                        <td>{s.ShipTo_State}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo Country</td>
                        <td>{s.ShipTo_Country}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo ZipCode</td>
                        <td>{s.ShipTo_ZipCode}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo PhoneNo</td>
                        <td>{s.ShipTo_PhoneNo}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo Contact</td>
                        <td>{s.ShipTo_Contact}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo Fax</td>
                        <td>{s.ShipTo_Fax}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo VAT</td>
                        <td>{s.ShipTo_VAT}</td>
                        <td></td>
                      </tr>
                    </table>
                  </>
                );
              })}
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default BOLReportsTable;
