import React from 'react'
import PageHeader from '../../components/PageHeader'
import ShipmentReportDateWiseTable from './ShipmentReportDateWiseTable'


const ShipmentReportDateWise = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Reports:- Order Shipped Report" dateLabel="Order Received Date"/>
      <ShipmentReportDateWiseTable />
    </div>
  )
}

export default ShipmentReportDateWise