import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "../../services/api";
import { Link } from "react-router-dom";
import {
  DataGridPremium,
  useGridApiRef,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridExcelExportMenuItem,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import OrderDetailPanel from "./OrderDetailPanel";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormLabel, Grid, MenuItem, OutlinedInput, Select, Stack } from "@mui/material";
import PDFViewer from "components/PDFViewer";
import { apiEndPoint } from "services";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import moment from "moment";

const columns = [
  {
    field: "ExternalOrderNo",
    headerClassName: "headingbg-color",
    headerName: "Customer Order",
  },
  {
    field: "OrderNumber",
    headerClassName: "headingbg-color",
    headerName: "IGD Order",
  },
  
  {
    field: "BuyerPOnumber",
    headerClassName: "headingbg-color",
    headerName: "Customer PO Number",
  },
  {
    field: "TotalOrdered",
    headerClassName: "headingbg-color",
    headerName: "Quantity Ordered",
  },
  {
    field: "orderStatus",
    headerClassName: "headingbg-color",
    headerName: "Order status",
  },
  {
    field: "ShipTo",
    renderCell: (params) => <Link href="#">{params.value && params.value.toString()}</Link>,
    headerClassName: "headingbg-color",
    headerName: "Ship To",
  },
  {
    field: "BillTo",
    renderCell: (params) => <Link href="#">{params.value && params.value.toString()}</Link>,
    headerClassName: "headingbg-color",
    headerName: "Bill To",
  },
  {
    field: "OrderDate",
    headerClassName: "headingbg-color",
    headerName: "PO Date",
  },
  {
    field: "ActualShipDate",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Actual Ship Date",
  },
  {
    field: "RequestedShipDate",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Requested Ship Date",
  },
  {
    field: "ScheduledShipDate",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Scheduled Ship Date",
  },
  {
    field: "CarrierCode",
    headerClassName: "headingbg-color",
    headerName: "SCAC",
  },
  {
    field: "CarrierName",
    headerClassName: "headingbg-color",
    headerName: "Carrier Name",
  },
  
  // {
  //   field: "Priority",
  //   headerClassName: "headingbg-color",
  //   headerName: "Priority",
  // },
  
  {
    field: "ConsigneeAddress",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Consignee Address",
  },
  
  {
    field: "PiecesOrderd",
    headerClassName: "headingbg-color",
    headerName: "Pieces Ordered",
  },
  {
    field: "TotalShipped",
    headerClassName: "headingbg-color",
    headerName: "Quantity Shipped",
  },
  {
    field: "PiecesShipped",
    headerClassName: "headingbg-color",
    headerName: "Pieces Shipped",
  },
  
  {
    field: "AppointmentNumber",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Appointment Number",
  },
  { field: "Door", headerClassName: "headingbg-color", headerName: "Door" },
  {
    field: "LoadNumber",
    headerClassName: "headingbg-color",
    headerName: "Load Number",
  },
  {
    field: "TrailerNumber",
    headerClassName: "headingbg-color",
    headerName: "Trailer Number",
  },
  {
    field: "BOLNumber",
    width: 150,
    renderCell: (params) => {
      if (params.row.signedBOLFile || params.row.sourceBOLFile) {
        return <Link href="#">{params.value && params.value.toString()}</Link>;
      } else {
        return params.value && params.value.toString();
      }
    },
    headerClassName: "headingbg-color",
    headerName: "BOL Number",
  },
  {
    field: "tracking_PROnumber",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Tracking/PRO",
  },
  {
    field: "orderType",
    headerClassName: "headingbg-color",
    headerName: "Order Type",
  },
  {
    field: "OrderRcvdDate",
    headerClassName: "headingbg-color",
    headerName: "Order Received",
  },
  {
    field: "TotalOrderLines",
    headerClassName: "headingbg-color",
    headerName: "Order Lines",
  },

  // { field: 'Route',headerClassName:"headingbg-color" , headerName: 'Route' },
  // { field: 'Stop',headerClassName:"headingbg-color" , headerName: 'Stop' },
];

const OrdersTable = () => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [orderData, setOrderData] = React.useState([]);
  const [orderDataBillShipTo, setOrderDataBillShipTo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [orderNo, setOrderNo] = React.useState('');
  const [isSigned, setIsSigned] = React.useState(0);
  const [signedFileName, setsignedFileName] = React.useState('');
  const [shippedDate, setShippedDate] = useState({from_shippedDt: '', to_shippedDt:''});
  const [poNumber, setPONumber] = useState('');
  const [customerOrder, setCustomerOrder] = useState('');
  const [itemNum, setItemNum] = useState('');
  const [formStatus, setFormStatus] = useState('all');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [bolNumber, setBolNumber] = useState('');
  const [detailData, setDetailData] = React.useState([]);
  const [clearFormData, setClearFormData] = useState(false);

  const [statusMenu, setStatusMenu] = useState([])
  

  const label = shippedDate.from_shippedDt ? shippedDate.from_shippedDt.format("MMMM D, YYYY") + " - " + shippedDate.to_shippedDt.format("MMMM D, YYYY") : '';

  const rows = orderData?.map((c, index) => ({
    id: index,
    OrderNumber: c.OrderNumber,
    Owner: c.Owner,
    orderType: c.orderType,
    WHSEID: c.WHSEID,
    OrderRcvdDate: c.OrderRcvdDate,
    OrderDate: c.OrderDate,
    orderStatus: c.orderStatus,
    BillTo: c.BillTo,
    ShipTo: c.ShipTo,
    Priority: c.Priority,
    CarrierCode: c.CarrierCode,
    CarrierName: c.CarrierName,
    ExternalOrderNo: c.ExternalOrderNo,

    RequestedShipDate: c.RequestedShipDate,
    ScheduledShipDate: c.ScheduledShipDate,
    ConsigneeAddress: c.ConsigneeAddress,
    TotalOrdered: c.TotalOrdered,
    TotalShipped: c.TotalShipped,
    ActualShipDate: c.ActualShipDate,
    TotalOrderLines: c.TotalOrderLines,
    PurchaseOrder: c.PurchaseOrder,
    tracking_PROnumber: c.tracking_PROnumber,
    AppointmentNumber: c.AppointmentNumber,
    Door: c.Door,
    // Route : c.Route,
    // Stop : c.Stop,
    LoadNumber: c.LoadNumber,
    TrailerNumber: c.TrailerNumber,
    BOLNumber: c.BOLNumber,
    signedBOLFile: c.signedBOLFile,
    sourceBOLFile: c.sourceBOLFile,
    isBOLSigned: c.isBOLSigned,
    PiecesOrderd: c.PiecesOrderd,
    PiecesShipped: c.PiecesShipped,
    BuyerPOnumber:c.BuyerPOnumber
  }));

  const fetchOrderTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      from_orderRcvdDt: startDate.format("YYYY-MM-DD"),
      to_orderRcvdDt: endDate.format("YYYY-MM-DD"),
      from_shippedDt: shippedDate.from_shippedDt ? shippedDate.from_shippedDt : '',
      to_shippedDt: shippedDate.to_shippedDt ? shippedDate.to_shippedDt : '',
      poNumber: poNumber ? poNumber : 'all',
      custOrderNumber: customerOrder ? customerOrder: 'all',
      trackingNumber: trackingNumber ? trackingNumber : 'all',
      bolNumber: bolNumber ? bolNumber :'all',
      itemNumber: itemNum ? itemNum :'all',
      orderStatus: formStatus ? formStatus :'all',
    };
    const response = await postRequest(
      "user/getRprt_SPSorders_forExcelExport",
      payload,
      headers
    );
    if(apiRef.current) {
      apiRef.current.setExpandedDetailPanels([])
    }
    setClearFormData(false);
    setOrderData(response.data.Payload.Table);
    setDetailData(response.data.Payload.Table1)
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOrderTableData();
  }, [startDate, endDate, userSetting]);

  useEffect(() => {
    getStatus();
  }, [])
  

  const handleCellSelectionModelChange = React.useCallback((newModel) => {
    Object.entries(newModel).forEach(([id, fields]) => {
      const updatedRow = apiRef.current.getRow(id);
      if (fields.BillTo || fields.ShipTo) {
        getBillToShipToDetails(updatedRow.OrderNumber);
        setOpenPopUp(true);
      } else if(fields.BOLNumber) {
        setOrderNo(updatedRow.OrderNumber);
        setIsSigned(updatedRow.isBOLSigned);
        const filepath = `${apiEndPoint}/`;
        setsignedFileName(filepath + updatedRow.signedBOLFile);
        if (updatedRow.isBOLSigned) {
          setOpen(true);
        } else {
          if (updatedRow.sourceBOLFile) {
            setsignedFileName(filepath + updatedRow.sourceBOLFile);
            setOpen(true);
          }
        }
      }
    });
  }, []);

  const getBillToShipToDetails = async (params) => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      orderNo: params,
    };
    const response = await postRequest(
      "user/GetOrderBillToShipToDtls",
      payload,
      headers
    );
    setOrderDataBillShipTo(response.data.Payload);
  };

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <OrderDetailPanel row={row} data={detailData}/>,
    [orderData]
  );

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const handleCallback = (start, end) => {
    setShippedDate({from_shippedDt: start, to_shippedDt: end})
  };

  const handleSearch = () => {
    fetchOrderTableData()
  }

  const clearForm = () => {
    setShippedDate({from_shippedDt: '', to_shippedDt:''});
    setPONumber('');
    setCustomerOrder('');
    setItemNum('');
    setFormStatus('all');
    setTrackingNumber('');
    setBolNumber('')
    setClearFormData(true);
  }
  useEffect(() => {
    if(clearFormData) {
      fetchOrderTableData()
    }
  }, [clearFormData])

  function CustomToolbar(props) {
    return (
      <GridToolbarContainer {...props}>
        <GridToolbarColumnsButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <GridToolbarFilterButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <GridToolbarDensitySelector slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        {/* <GridToolbarExport /> */}
        <CustomExportButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  const exceljsPreProcess = async ({ workbook, worksheet }) => {
    // Set document meta data
    workbook.creator = 'MUI-X team';
    workbook.created = new Date();
    worksheet.name = "Outbound"
    
    let worksheetMy = workbook.addWorksheet("Outbound Details");

    
    worksheetMy.state = 'visible';

    worksheetMy.columns = [
      { header: 'Order Number', key: 'OrderNumber' },
      { header: 'Order Line No', key: 'orderLineNo' },
      { header: 'Item No', key: 'ItemNo' },
      { header: 'Item Description', key: 'ItemDescription' },
      { header: 'Orginal Qty', key: 'ORIGINALQTY' },
      { header: 'Open Qty', key: 'OPENQTY' },
      { header: 'Shipped Qty', key: 'SHIPPEDQTY' },
      { header: 'Adjusted Qty', key: 'ADJUSTEDQTY' },
      { header: 'Qty Allocated', key: 'QTYALLOCATED' },
      { header: 'Qty Picked', key: 'QTYPICKED' },
      { header: 'UOM', key: 'UOM' },
      { header: 'Pack Key', key: 'PACKKEY' },
      { header: 'Product Weight', key: 'PRODUCT_WEIGHT' },
      { header: 'Product Cube', key: 'PRODUCT_CUBE' },
      { header: 'Actual Ship Date', key: 'ACTUALSHIPDATE' },
      { header: 'SPS Tracking Number', key: 'SPSTrackingNumber' },
    ];

    worksheetMy.addRows(detailData);
  
    // Customize default excel properties
    worksheet.properties.defaultRowHeight = 30;
  
    // Create a custom file header
    // worksheet.mergeCells('A1:C2');
    // worksheet.getCell('A1').value =
    //   'This is an helping document for the MUI-X team.\nPlease refer to the doc for up to date data.';
  
    // worksheet.getCell('A1').border = {
    //   bottom: { style: 'medium', color: { argb: 'FF007FFF' } },
    // };
  
    // worksheet.getCell('A1').font = {
    //   name: 'Arial Black',
    //   size: 14,
    // };
    // worksheet.getCell('A1').alignment = {
    //   vertical: 'top',
    //   horizontal: 'center',
    //   wrapText: true,
    // };
    // worksheet.addRow([]);
  };
  const exceljsPostProcess = async ({ worksheet }) => {
    // // add a text after the data
    // worksheet.addRow({}); // Add empty row
  
    // worksheet.addRow(['Those data are for internal use only']);
  };
  
  const excelOptions = {
    exceljsPreProcess,
    exceljsPostProcess,
  };

  function CustomExportButton(props) {
    return (
      <GridToolbarExportContainer {...props}>
       <GridExcelExportMenuItem options={excelOptions}/>
      </GridToolbarExportContainer>
    );
  }

  const getStatus = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      masterListFor: 'orderStatus'
    };
    const response = await postRequest(
      "user/Get_MasListVals",
      payload,
      headers
    );

    setStatusMenu(response.data.Payload.Table)
  }

  return (
    <>
      <div>
          <Grid container spacing={2}>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="Shipped-date" sx={{fontSize:"0.9rem"}}>
                Shipped Date
              </FormLabel>
              <DateRangePicker
                initialSettings={{
                  startDate: shippedDate.from_shippedDt ? shippedDate.from_shippedDt.toDate() : moment().toDate(),
                  endDate: shippedDate.to_shippedDt ? shippedDate.to_shippedDt.toDate() : moment().toDate() ,
                }}
                onCallback={handleCallback}
              >
                <div id="reportrange" className="box-with-icon filterCalendar">
                  <span>{label}</span>
                  <CalendarMonthOutlinedIcon />
                </div>
              </DateRangePicker>
            </Grid>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="po-number" sx={{fontSize:"0.9rem"}} >
                Po Number
              </FormLabel>
              <div>
              <OutlinedInput
                name="po-number"
                placeholder="Enter PO Number"
                fullWidth
                inputProps={{
                  style: {
                    height: "0.3rem",
                  },
                }}
                onChange={(e) => setPONumber(e.target.value)}
                value={poNumber}
              />
              </div>
            </Grid>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="item-number" sx={{fontSize:"0.9rem"}}>
                Item Number
              </FormLabel>
              <div>
              <OutlinedInput
                name="item-number"
                placeholder="Enter Item Number"
                fullWidth
                inputProps={{
                  style: {
                    height: "0.3rem",
                  },
                }}
                value={itemNum}
                onChange={(e) => setItemNum(e.target.value)}
              />
              </div>
            </Grid>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="status" sx={{fontSize:"0.9rem"}}>
                Status
              </FormLabel>
              <div>
              <Select
                SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                labelId="status"
                id="status"
                value={formStatus}
                onChange={(e) => setFormStatus(e.target.value)}
                fullWidth
                inputProps={{
                  style: {
                    maxHeight: "0.3rem",
                  },
                }}
              >
                <MenuItem value="all">All</MenuItem>
                {statusMenu.map( item => <MenuItem value={item.value}>{item.text}</MenuItem>)}
              </Select>
              </div>
            </Grid>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="Customer-Order" sx={{fontSize:"0.9rem"}}>
                Customer Order
              </FormLabel>
              <div>
              <OutlinedInput
                name="Customer-Order"
                placeholder="Enter Customer Order"
                fullWidth
                inputProps={{
                  style: {
                    height: "0.3rem",
                  },
                }}
                value={customerOrder}
                onChange={(e) => setCustomerOrder(e.target.value)}
              />
              </div>
            </Grid>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="tracking-number" sx={{fontSize:"0.9rem"}}>
                Tracking Number
              </FormLabel>
              <div>
              <OutlinedInput
                name="tracking-number"
                placeholder="Enter Tracking Number"
                fullWidth
                inputProps={{
                  style: {
                    height: "0.3rem",
                  },
                }}
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
              />
              </div>
            </Grid>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="bol-number" sx={{fontSize:"0.9rem"}}>
                BOL Number
              </FormLabel>
              <div>
              <OutlinedInput
                name="bol-number"
                placeholder="Enter BOL Number"
                fullWidth
                inputProps={{
                  style: {
                    height: "0.3rem",
                  },
                }}
                value={bolNumber}
                onChange={(e) => setBolNumber(e.target.value)}
              />
              </div>
            </Grid>

          <Grid item xs= {12} md= {12} >
            <Stack direction="row" spacing={2}>
              <Button color="grey"  variant="contained" onClick={clearForm} size="small">Reset</Button>
              <Button variant="contained" onClick={handleSearch} size="small">
                Apply Filter
              </Button>
            </Stack>
          </Grid>

          </Grid>
        </div>
      <div
        style={{
          height: 700,
          width: "100%",
          marginTop: "20px",
          background: "#fff",
        }}
      >
        <DataGridPremium
          loading={isLoading}
          rowSelection={false}
          apiRef={apiRef}
          cellSelection={true}
          columns={columns}
          rows={rows}
          onCellSelectionModelChange={handleCellSelectionModelChange}
          slots={{ toolbar: CustomToolbar }}
          getDetailPanelHeight={() => 400}
          getDetailPanelContent={getDetailPanelContent}
          sx={{
            "& .MuiDataGrid-detailPanel": {
              overflow: "visible",
            },
            "& .MuiButton-text": { fontSize:  "14px"}
          }}
          pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
        />
      </div>

      {openPopUp && (
        <Box sx={{ width: "100%", height: "200px" }}>
          <Dialog
            open={openPopUp}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
            }}
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle
              className="colordivSumHead font-bold"
              id="form-dialog-title"
            >
              Bill to/Ship to Details:
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: 800, height: 450 }}>
                {orderDataBillShipTo?.map((s) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <table
                          style={{
                            width: "100%",
                            marginBottom: "20px",
                            tableLayout: "fixed",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                className="colordivSumHead font-bold"
                                style={{ textAlign: "left" }}
                              >
                                Bill To Details :
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-bold table-align table-width">
                                Order Number
                              </td>
                              <td className="table-align">{s.OrderNumber}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                {" "}
                                BillTo Name
                              </td>
                              <td className="table-align">{s.BillTo_Name}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                {" "}
                                BillTo Address
                              </td>
                              <td className="table-align">
                                {s.BillTo_Address}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                {" "}
                                BillTo City
                              </td>
                              <td className="table-align">{s.BillTo_City}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo State
                              </td>
                              <td className="table-align">{s.BillTo_State}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo Country
                              </td>
                              <td className="table-align">
                                {s.BillTo_Country}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo ZipCode
                              </td>
                              <td className="table-align">
                                {s.BillTo_ZipCode}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo PhoneNo
                              </td>
                              <td className="table-align">
                                {s.BillTo_PhoneNo}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo Contact
                              </td>
                              <td className="table-align">
                                {s.BillTo_Contact}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo VAT
                              </td>
                              <td className="table-align">{s.BillTo_VAT}</td>
                            </tr>
                          </tbody>
                        </table>

                        <table style={{ width: "100%", tableLayout: "fixed" }}>
                          <thead>
                            <tr>
                              <th
                                className="colordivSumHead font-bold"
                                style={{ textAlign: "left" }}
                              >
                                Ship To Details :
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-bold table-align table-width">
                                Order Number
                              </td>
                              <td className="table-align">{s.OrderNumber}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Name
                              </td>
                              <td className="table-align">{s.ShipTo_Name}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Address
                              </td>
                              <td className="table-align">
                                {s.ShipTo_Address}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo City
                              </td>
                              <td className="table-align">{s.ShipTo_City}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo State
                              </td>
                              <td className="table-align">{s.ShipTo_State}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Country
                              </td>
                              <td className="table-align">
                                {s.ShipTo_Country}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo ZipCode
                              </td>
                              <td className="table-align">
                                {s.ShipTo_ZipCode}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo PhoneNo
                              </td>
                              <td className="table-align">
                                {s.ShipTo_PhoneNo}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Contact
                              </td>
                              <td className="table-align">
                                {s.ShipTo_Contact}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Fax
                              </td>
                              <td className="table-align">{s.ShipTo_Fax}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo VAT
                              </td>
                              <td className="table-align">{s.ShipTo_VAT}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  );
                })}
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      )}

      {open && (
        <Dialog
          id="signDialog"
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "left",
          }}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Order No: {orderNo}
          </DialogTitle>
          <DialogContent>
            <PDFViewer
              fileName={signedFileName}
              action={() => {}}
              isSigned={isSigned}
              showSign={false}
              orderNo={orderNo}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default OrdersTable;
