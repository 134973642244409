import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import SimpleBar from "simplebar-react";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MetisMenu from "@metismenu/react";
import "simplebar-react/dist/simplebar.min.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "metismenujs/style";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InventoryIcon from "@mui/icons-material/Inventory";
import { postRequest } from "../../services/api";
import { FaWarehouse } from "react-icons/fa";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const SideMenuContent = () => {
  const [navData, setNavData] = useState([]);

  const getNavData = async () => {
    const userData = JSON.parse(localStorage.getItem("userCredential"));
    try {
      const headers = {
        Authorization: `Bearer ${userData.token}`,
      };
      const payLoad = { userId: userData.Id };
      if (JSON.parse(localStorage.getItem("navMenu"))) {
        setNavData(JSON.parse(localStorage.getItem("navMenu")));
        return;
      }
      const res = await postRequest("User/GetUserNavMenus", payLoad, headers);
      localStorage.setItem("navMenu", JSON.stringify(res.data.Payload));
      setNavData(res.data.Payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNavData();
  }, []);

  const checkMenuPermission = (name) => {
    return navData && navData.some((menu) => menu.menuName ===name);
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100">
        <MetisMenu>
          {checkMenuPermission("Dashboard") && (
            <li>
              <NavLink to="/dashboard">
                <DashboardOutlinedIcon />
                <span>Dashboard</span>
              </NavLink>
            </li>
          )}

          <li
            style={{
              display: checkMenuPermission("Warehouse") ? "contents" : "none",
            }}
          >
            <Link to="/#" className="has-arrow">
              <FaWarehouse size={24} />
              <span>Warehouse</span>
            </Link>
            <ul>
            <li
                style={{
                  display: checkMenuPermission("IN-TRANSIT") ? "contents" : "none",
                }}
              >
                <NavLink to="/intransit">In Transit</NavLink>
              </li>
              <li
                style={{
                  display: checkMenuPermission("ASN") ? "contents" : "none",
                }}
              >
                <NavLink to="/asn">Inbound</NavLink>
              </li>
              <li
                style={{
                  display: checkMenuPermission("INVENTORY")
                    ? "contents"
                    : "none",
                }}
              >
                <NavLink to="/inventory">Inventory</NavLink>
              </li>
              <li
                style={{
                  display: checkMenuPermission("ORDERS") ? "contents" : "none",
                }}
              >
                <NavLink to="/orders">Outbound</NavLink>
              </li>
            </ul>
          </li>
          <li
            style={{
              display: checkMenuPermission("Billing") ? "contents" : "none",
            }}
          >
            <Link to="/#" className="has-arrow">
              <AttachMoneyIcon size={24} />
              <span>Billing</span>
            </Link>
            <ul>
            <li
                style={{
                  display: checkMenuPermission("Invoice") ? "contents" : "none",
                }}
              >
                <NavLink to="/reportInvoiceDetails">Invoice</NavLink>
              </li>
            </ul>
          </li>
          <li
            style={{
              display: checkMenuPermission("Reports") ? "contents" : "none",
            }}
          >
            <Link to="/#" className="has-arrow">
              <DescriptionOutlinedIcon />
              <span>Reports</span>
            </Link>
            <ul>
              <li
                style={{
                  display: checkMenuPermission("Inbound") ? "contents" : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  Inbound
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("ASN Receipts")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/ansRec">Received Summary</NavLink>
                  </li>
                </ul>
              </li>
              <li
                style={{
                  display: checkMenuPermission("Outbound")
                    ? "contents"
                    : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  Outbound
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("Shipment Datewise")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/reportOrderDate">
                      Order Shipped Report
                    </NavLink>
                  </li>
                  {/* <li
                    style={{
                      display: checkMenuPermission("Shipment Carrierwise")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/reportOrderCarrier">
                      Shipment Report Carrier wise
                    </NavLink>
                  </li> */}
                  <li
                    style={{
                      display: checkMenuPermission("SPS-Orders")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/spsOrders">
                      SPS Orders
                    </NavLink>
                  </li>
                  <li
                    style={{
                      display: checkMenuPermission("Open-Orders")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/openOrderReport">
                      Open Order Report
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li
                style={{
                  display: checkMenuPermission("Inventory")
                    ? "contents"
                    : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  Inventory
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("Transactions")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/invnTrans">Current Inventory Transaction</NavLink>
                  </li>
                  <li
                    style={{
                      display: checkMenuPermission("Monthly Item Activity")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/monthlyInvnTrans">Monthly Inventory Transaction</NavLink>
                  </li>
                </ul>
              </li>
              {/* <li
                style={{
                  display: checkMenuPermission("Billing") ? "contents" : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  Billing
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("Invoice")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/reportInvoiceDetails">Invoice</NavLink>
                  </li>
                </ul>
              </li> */}
              <li
                style={{
                  display: checkMenuPermission("Container")
                    ? "contents"
                    : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  In Transit
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("Container Details")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/reportContainerDetails">
                    In Transit Details
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li
                style={{
                  display: checkMenuPermission("BOL")
                    ? "contents"
                    : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  BOL
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("BOL")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/reportBillOfLanding">
                      BOL Details
                    </NavLink>
                  </li>
                </ul>
              </li>

              {/* <li
                style={{
                  display: checkMenuPermission("BOL") ? "contents" : "none",
                }}
              >
                <NavLink to="/reportBillOfLanding">
                  <span>BOL Details</span>
                </NavLink>
              </li> */}
            </ul>
          </li>
          <li
            style={{
              display: checkMenuPermission("Admin") ? "contents" : "none",
            }}
          >
            <Link to="/#" className="has-arrow">
              <AdminPanelSettingsOutlinedIcon />
              <span>Admin</span>
            </Link>
            <ul>
              <li
                style={{
                  display: checkMenuPermission("App Settings")
                    ? "contents"
                    : "none",
                }}
              >
                <NavLink to="/userTabs">App Setting</NavLink>
              </li>
            </ul>
          </li>

          <li
            style={{
              display: checkMenuPermission("Utilities") ? "contents" : "none",
            }}
          >
            <Link to="/#" className="has-arrow">
              <HiMiniWrenchScrewdriver size={24} />
              <span>Utilities</span>
            </Link>
            <ul>
              <li
                style={{
                  display: checkMenuPermission("eSign BOL")
                    ? "contents"
                    : "none",
                }}
              >
                <NavLink to="/bOLUploadFile">e-Sign BOL</NavLink>
              </li>
            </ul>
          </li>
          {/* <li
            style={{
              display: checkMenuPermission("eSign BOL") ? "contents" : "none",
            }}
          >
            <NavLink to="/bOLUploadFile">
              <DescriptionIcon />
              <span>e-Sign BOL</span>
            </NavLink>
          </li> */}
        </MetisMenu>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SideMenuContent;
