import React from 'react'
import PageHeader from '../../components/PageHeader'
import ASNReceiptTable from './ASNReceiptTable'


const ASNReceipt = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Reports:- Received Summary" />
      <ASNReceiptTable />
    </div>
  )
}

export default ASNReceipt