import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import AsnTable from "pages/ASN/AsnTable";
import OrderNumberDetails from "pages/Orders/OrderNumberDetails";
import OrdersTable from "pages/Orders/OrdersTable";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";

const InventoryDetailPanel = ({ row, eventData, data }) => {
  const [isASN, setIsASN] = React.useState(false);
  const [dateValue, setDateValue] = React.useState("");
  const [asnNo, setasnNumber] = React.useState("");

  const { user, userSetting } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const apiRef = useGridApiContext();
  const [open, setOpen] = React.useState(false);
  const [orderItemsDataDetails, setorderItemsDataDetails] = React.useState([]);

  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  }, [apiRef]);

  const columnsItem = [
    {
      field: "ItemNum",
      headerClassName: "headingbg-color",
      headerName: "Item Number",
    },
    {
      field: "ItemDescription",
      headerClassName: "headingbg-color",
      headerName: "Item Description",
      width: 220,
    },
    {
      field: "Balance",
      headerClassName: "headingbg-color",
      headerName: "Balance",
    },
    {
      field: "Quantity",
      headerClassName: "headingbg-color",
      headerName: "Quantity",
    },
    {
      field: "TransactionNum",
      headerClassName: "headingbg-color",
      headerName: "Transaction Number",
    },
    {
      field: "TransactionType",
      headerClassName: "headingbg-color",
      headerName: "Transaction Type",
    },
    { field: "Date", headerClassName: "headingbg-color", headerName: "Date" },
    {
      field: "GrossWeight",
      headerClassName: "headingbg-color",
      headerName: "Gross Weight",
    },
    {
      field: "NetWeight",
      headerClassName: "headingbg-color",
      headerName: "Net Weight",
    },
    {
      field: "TareWeight",
      headerClassName: "headingbg-color",
      headerName: "Tare Weight",
    },
  ];

  const rowItems = orderItemsDataDetails?.map((c, index) => ({
    id: index,
    ItemNum: c.ItemNum,
    ItemDescription: c.ItemDescription,
    Balance: c.Balance,
    Quantity: c.Quantity,
    TransactionNum: c.TransactionNum,
    TransactionType: c.TransactionType,
    Date: c.Date,
    GrossWeight: c.GrossWeight,
    NetWeight: c.NetWeight,
    TareWeight: c.TareWeight,
  }));

  const getOrderItemsDetailsApiData = async () => {
    setIsLoading(true);
    // let headers = {
    //   "Content-Type": "application/json;",
    //   Authorization: `Bearer ${user.token}`,
    // };

    // const statusValue = eventData.$d;
    // const dateDaoily = statusValue.toISOString().substring(0, 10);
    // let payload = {
    //   custCode: userSetting[0].currCustomer,
    //   whseCode: userSetting[0].currWarehouse,
    //   itemNum: row.Item,
    //   asOfDate: dateDaoily,
    // };
    // const response = await postRequest(
    //   "user/getDashData_itemInvtTrans_lastN",
    //   payload,
    //   headers
    // );
    let filterData= data.filter(item => item.ItemNum === row.Item)
    setorderItemsDataDetails(filterData);
    setIsLoading(false);
  };

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      "viewportInnerSizeChange",
      handleViewportInnerSizeChange
    );
  }, [apiRef, handleViewportInnerSizeChange]);

  useEffect(() => {
    getOrderItemsDetailsApiData();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handlerRowClick = (rowprops) => {
    const rowType = rowprops.row.TransactionType
    const rowDate = rowprops.row.Date
    const asnNum = rowprops.row.TransactionNum
    if (rowType == "ASN") {
        setIsASN(true)
        setDateValue(rowDate)
        setOpen(true);
        //setrowClicked(true)
        setasnNumber(asnNum)
    }
    else {
        setIsASN(false)
        setDateValue(rowDate)
        setOpen(true);
        //setrowClicked(true)
        setasnNumber(asnNum)
    }
  };

  return (
    <>
      <Stack
        sx={{
          py: 1,
          height: "350px",
          boxSizing: "border-box",
          position: "sticky",
          left: 0,
          width,
        }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "96%",
            p: 2,
            background: "#fff",
          }}
        >
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography
              className="colordivSumHead"
              fontWeight="bold"
              variant="h6"
            >{`Item No - ${row.Item}`}</Typography>
            <Box sx={{ width: "100%", height: 300 }}>
              <DataGridPremium
                slots={{ toolbar: GridToolbar }}
                rowSelection={true}
                onRowClick={handlerRowClick}
                columns={columnsItem}
                rows={rowItems}
                sx={{ flex: 1, "& .MuiButton-text": { fontSize:  "14px"} }}
                hideFooter
                loading={isLoading}
              />
            </Box>
          </Stack>
        </Paper>
      </Stack>
      <Box sx={{ width: "100%", height: "200px" }}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            {isASN ? 'ASN Detail' : 'Order Details:'}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: 1100, height: 550 }}>
              {isASN ? (
                <AsnTable
                  status="all"
                  fromDate={dateValue}
                  toDate={dateValue}
                  asnNumber={asnNo}
                />
              ) : (
                <OrderNumberDetails
                  orderNumber={asnNo}
                />
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default memo(InventoryDetailPanel);
