import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";

const OrderDetailPanel = ({ row, data }) => {
  const { user, userSetting } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const apiRef = useGridApiContext();
  const [open, setOpen] = React.useState(false);
  const [orderItemsData, setorderItemsData] = React.useState([]);
  const [orderItemsDataDetails, setorderItemsDataDetails] = React.useState([]);

  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  }, [apiRef]);

  const columnsItem = [
    { field: "OrderNumber", headerName: "Order Number", headerClassName: "headingbg-color", },
    { field: "orderLineNo", headerName: "Order Line No", headerClassName: "headingbg-color", },
    // { field: 'OrderDetailID', headerName: 'Order Detail ID' },
    { field: "ItemNo", headerName: "Item No", headerClassName: "headingbg-color", },

    { field: "ItemDescription", headerName: "Item Description", headerClassName: "headingbg-color", },
    { field: "ORIGINALQTY", headerName: "ORIGINAL QTY", headerClassName: "headingbg-color", },
    { field: "OPENQTY", headerName: "OPEN QTY", headerClassName: "headingbg-color", },
    { field: "SHIPPEDQTY", headerName: "SHIPPED QTY", headerClassName: "headingbg-color", },
    { field: "ADJUSTEDQTY", headerName: "ADJUSTED QTY", headerClassName: "headingbg-color", },
    { field: "QTYALLOCATED", headerName: "QTY ALLOCATED", headerClassName: "headingbg-color", },

    { field: "QTYPICKED", headerName: "QTY PICKED", headerClassName: "headingbg-color", },
    { field: "UOM", headerName: "UOM", headerClassName: "headingbg-color", },
    { field: "PACKKEY", headerName: "PACK KEY", headerClassName: "headingbg-color", },
    { field: "PRODUCT_WEIGHT", headerName: "PRODUCT WEIGHT", headerClassName: "headingbg-color", },
    { field: "PRODUCT_CUBE", headerName: "PRODUCT CUBE", headerClassName: "headingbg-color", },
    { field: "ACTUALSHIPDATE", headerName: "ACTUAL SHIPDATE", headerClassName: "headingbg-color", },
    { field: "SPSTrackingNumber", headerName: "SPS Tracking Number", headerClassName: "headingbg-color", },
  ];

  const rowItems = orderItemsData.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    OrderNumber: c.OrderNumber,
    orderLineNo: c.orderLineNo,
    OrderDetailID: c.OrderDetailID,
    ItemNo: c.ItemNo,
    ItemDescription: c.ItemDescription,
    ORIGINALQTY: c.ORIGINALQTY,
    OPENQTY: c.OPENQTY,
    SHIPPEDQTY: c.SHIPPEDQTY,
    ADJUSTEDQTY: c.ADJUSTEDQTY,
    QTYALLOCATED: c.QTYALLOCATED,
    QTYPICKED: c.QTYPICKED,
    UOM: c.UOM,
    PACKKEY: c.PACKKEY,
    PRODUCT_WEIGHT: c.PRODUCT_WEIGHT,
    PRODUCT_CUBE: c.PRODUCT_CUBE,
    ACTUALSHIPDATE: c.ACTUALSHIPDATE,
    BUYERPO: c.BUYERPO,
    FULFILLQTY: c.FULFILLQTY,
    PROCESSEDQTY: c.PROCESSEDQTY,
    QTYTOPROCESS: c.QTYTOPROCESS,
    SPSTrackingNumber: c.SPSTrackingNumber,
  }));

  const columnsItemDetails = [
    { field: "Item", headerName: "Item" },
    { field: "Description", headerName: "Description" },
    { field: "Pack", headerName: "Pack" },
    { field: "GrossWeight", headerName: "Gross Weight" },

    { field: "NetWeight", headerName: "Net Weight" },
    { field: "Cube", headerName: "Cube" },
    { field: "Class", headerName: "Class" },
    { field: "ItemGroup1", headerName: "Item Group1" },
    { field: "PICKCODE", headerName: "PICK CODE" },
    { field: "CartonGroup", headerName: "Carton Group" },

    { field: "PUTCODE", headerName: "PUT CODE" },
    { field: "PutAwayLocation", headerName: "Put Away Location" },
    { field: "PutAwayZone", headerName: "Put Away Zone" },
    { field: "DateItemLastCounted", headerName: "Date Item Last Counted" },
    { field: "InboundQCLocation", headerName: "Inbound QC Location" },
    { field: "RotateBy", headerName: "RotateBy" },

    {
      field: "RFDefaultReceivingPack",
      headerName: "RF Default Receiving Pack",
    },
    { field: "RFDefaultReceivingUOM", headerName: "RF Default Receiving UOM" },
    { field: "ReturnLocation", headerName: "Return Location" },
    { field: "InboundQCLoc", headerName: "Inbound QCLoc" },
    { field: "ReceiptValidation", headerName: "Receipt Validation" },
    { field: "OutboundQCLoc", headerName: "Outbound QC Loc" },
  ];

  const rowItemsDetails = orderItemsDataDetails.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    Warehouse: c.Warehouse,
    Item: c.Item,
    Description: c.Description,
    Pack: c.Pack,
    GrossWeight: c.GrossWeight,
    NetWeight: c.NetWeight,
    Cube: c.Cube,
    Class: c.Class,
    ItemGroup1: c.ItemGroup1,
    PICKCODE: c.PICKCODE,
    CartonGroup: c.CartonGroup,
    PUTCODE: c.PUTCODE,
    PutAwayLocation: c.PutAwayLocation,
    PutAwayZone: c.PutAwayZone,
    DateItemLastCounted: c.DateItemLastCounted,
    InboundQCLocation: c.InboundQCLocation,
    RotateBy: c.RotateBy,
    RFDefaultReceivingPack: c.RFDefaultReceivingPack,
    RFDefaultReceivingUOM: c.RFDefaultReceivingUOM,
    ReturnLocation: c.ReturnLocation,
    InboundQCLoc: c.InboundQCLoc,
    ReceiptValidation: c.ReceiptValidation,
    OutboundQCLoc: c.OutboundQCLoc,
  }));

  const createItemsData = async (rowprops) => {
    getOrderItemsDetailsApiData(rowprops.row.ItemNo);
    setOpen(true);
    return orderItemsDataDetails?.map((c, index) => ({
      id: index,
      Item: c.Item,
      Description: c.Description,
      Pack: c.Pack,
      GrossWeight: c.GrossWeight,
    }));
  };

  const getOrderItemsDetailsApiData = async (itemNo) => {
    setIsLoading(true)
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      itemNo: itemNo,
    };
    const response = await postRequest("user/GetItemDetails", payload, headers);
    setorderItemsDataDetails(response.data.Payload);
    setIsLoading(false)
  };

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleViewportInnerSizeChange,

    );
  }, [apiRef, handleViewportInnerSizeChange]);

  useEffect(() => {
    getOrderItemsApiData();
  }, [])
  

  const getOrderItemsApiData = async () => {
    // let headers = {
    //   "Content-Type": "application/json;",
    //   Authorization: `Bearer ${user.token}`,
    // };
    // let payload = {
    //   custCode: userSetting[0].currCustomer,
    //   whseCode: userSetting[0].currWarehouse,
    //   orderNo: row.OrderNumber,
    // };
    // const response = await postRequest("user/GetOrderItems", payload, headers);
    let filterData= data.filter(item => item.OrderNumber === row.OrderNumber)
    setorderItemsData(filterData);
    setIsLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack
        sx={{
          py: 1,
          height: "350px",
          boxSizing: "border-box",
          position: "sticky",
          left: 0,
          width
        }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "96%",
            p: 2,
            background: "#fff",
          }}
        >
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography
              className="colordivSumHead"
              fontWeight="bold"
              variant="h6"
            >{`Details for order: #${row.OrderNumber}`}</Typography>
            <Box sx={{ width: "100%", height: 300 }}>
              <DataGridPremium
                slots={{ toolbar: GridToolbar }}
                rowSelection={true}
                onRowClick={createItemsData}
                columns={columnsItem}
                rows={rowItems}
                sx={{ flex: 1, "& .MuiButton-text": { fontSize:  "14px"} }}
                hideFooter
                loading={isLoading}
        
              />
            </Box>
          </Stack>
        </Paper>
      </Stack>

      <Box sx={{ width: "100%", height: "200px" }}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Item Properties:
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%", height: 120 }}>
              <DataGridPremium
                columns={columnsItemDetails}
                rows={rowItemsDetails}
                density="compact"
                hideFooter
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default OrderDetailPanel;
