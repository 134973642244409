import { Box } from '@mui/material'
import React from 'react'
import PageHeader from '../../components/PageHeader'
import OrdersTable from './OrdersTable'

const SpsOrder = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Outbound: SPS Orders" dateLabel="Order Received Date"/>
      <OrdersTable />
    </div>
  )
}

export default SpsOrder