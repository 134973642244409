import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "../../services/api";
import {
  DataGridPremium,
  GridToolbar,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridToolbarExportContainer,
  GridExcelExportMenuItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import InventoryDetailPanel from "./InventoryDetailPanel";
import { Box } from "@mui/material";

const columns = [
  { field: "Item", headerClassName: "headingbg-color", headerName: "Item" },
  { field: "Pack", headerClassName: "headingbg-color", headerName: "Pack" },
  {
    field: "Description",
    headerClassName: "headingbg-color",
    headerName: "Description",
  },
  {
    field: "OnHandCases",
    headerClassName: "headingbg-color",
    headerName: "On Hand QTY",
  },
  {
    field: "OnHandQuantity",
    headerClassName: "headingbg-color",
    headerName: "On Hand EA",
  },

  // { field: 'Lot', headerClassName: "headingbg-color", headerName: 'Lot' },
  // { field: 'Location', headerClassName: "headingbg-color", headerName: 'Location' },

  // { field: 'LPN', headerClassName: "headingbg-color", headerName: 'LPN' },

  {
    field: "InTransitQty",
    headerClassName: "headingbg-color",
    headerName: "In Transit Qty",
  },
  {
    field: "InTransitPcs",
    headerClassName: "headingbg-color",
    headerName: "In Transit EA",
  },
  {
    field: "AvailableQty",
    headerClassName: "headingbg-color",
    headerName: "Available Qty",
  },
  {
    field: "AvailablePcs",
    headerClassName: "headingbg-color",
    headerName: "Available Pcs",
  },
  {
    field: "AllocatedQty",
    headerClassName: "headingbg-color",
    headerName: "Allocated Qty",
  },
  {
    field: "AllocatedPcs",
    headerClassName: "headingbg-color",
    headerName: "Allocated Pcs",
  },
  {
    field: "PickedQty",
    headerClassName: "headingbg-color",
    headerName: "Picked Qty",
  },
  {
    field: "PickedPcs",
    headerClassName: "headingbg-color",
    headerName: "Picked Pcs",
  },
  {
    field: "CUBE",
    headerClassName: "headingbg-color",
    headerName: "Item Cuft",
  },
  {
    field: "TotalCUBE",
    headerClassName: "headingbg-color",
    headerName: "Total Cuft",
  },

  // { field: 'Status', headerClassName: "headingbg-color", headerName: 'Status' },
  {
    field: "GrossWeight",
    headerClassName: "headingbg-color",
    headerName: "Gross Weight",
  },
  // { field: 'NetWeight', headerClassName: "headingbg-color", headerName: 'Net Weight' },
  // { field: 'TareWeight', headerClassName: "headingbg-color", headerName: 'Tare Weight' },
  // { field: 'dataAsOfDT', headerClassName: "headingbg-color", headerName: 'Date', width: 150 },
];

const InventoryTransactionTable = ({ eventData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, userSetting } = useSelector((state) => state.user);
  const [balanceData, setBalanceData] = React.useState([]);
  const [detailData, setDetailData] = React.useState([]);

  const rows = balanceData.map((c, index) => ({
    id: index,
    Item: c.Item,
    Pack: c.Pack,
    Description: c.Description,
    OnHandCases: c.OnHandCases,
    Lot: c.Lot,
    Location: c.Location,
    LPN: c.LPN,
    OnHandQuantity: c.OnHandQuantity,
    InTransitQty: c.InTransitQty,
    AvailableQty: c.AvailableQty,
    AllocatedQty: c.AllocatedQty,
    PickedQty: c.PickedQty,
    Status: c.Status,
    GrossWeight: c.GrossWeight,
    NetWeight: c.NetWeight,
    TareWeight: c.TareWeight,
    dataAsOfDT: c.dataAsOfDT,
    InTransitPcs: c.InTransitPcs,
    AvailablePcs: c.AvailablePcs,
    AllocatedPcs: c.AllocatedPcs,
    PickedPcs: c.PickedPcs,
    CUBE: c.CUBE,
    TotalCUBE: c.TotalCUBE,
  }));

  const fetchAsnTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };

    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      item: "all",
      asOfDate: eventData,
    };
    const response = await postRequest(
      "user/getRprt_inventoryBal_forExcelExport",
      payload,
      headers
    );
    setBalanceData(response.data.Payload.Table);
    setDetailData(response.data.Payload.Table1);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAsnTableData();
  }, [userSetting, eventData]);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <InventoryDetailPanel row={row}  data={detailData}/>,
    [balanceData]
  );

  function CustomToolbar(props) {
    return (
      <GridToolbarContainer {...props}>
        <GridToolbarColumnsButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <GridToolbarFilterButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <GridToolbarDensitySelector slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        {/* <GridToolbarExport /> */}
        <CustomExportButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }



  const exceljsPreProcess = async ({ workbook, worksheet }) => {
    // Set document meta data
    workbook.creator = 'MUI-X team';
    workbook.created = new Date();
    worksheet.name = "Inventory"
    
    let worksheetMy = workbook.addWorksheet("Inventory Details");

    
    worksheetMy.state = 'visible';

    worksheetMy.columns = [
      { header: 'Item Number', key: 'ItemNum' },
      { header: 'Item Description', key: 'ItemDescription' },
      { header: 'Balance', key: 'Balance' },
      { header: 'Quantity', key: 'Quantity' },
      { header: 'Transaction Number', key: 'TransactionNum' },
      { header: 'Transaction Type', key: 'TransactionType' },
      { header: 'Date', key: 'Date' },
      { header: 'Gross Weight', key: 'GrossWeight' },
      { header: 'Net Weight', key: 'NetWeight' },
      { header: 'TareWeight', key: 'TareWeight' },
    ];

    worksheetMy.addRows(detailData);
  
    // Customize default excel properties
    worksheet.properties.defaultRowHeight = 30;
  
    // Create a custom file header
    // worksheet.mergeCells('A1:C2');
    // worksheet.getCell('A1').value =
    //   'This is an helping document for the MUI-X team.\nPlease refer to the doc for up to date data.';
  
    // worksheet.getCell('A1').border = {
    //   bottom: { style: 'medium', color: { argb: 'FF007FFF' } },
    // };
  
    // worksheet.getCell('A1').font = {
    //   name: 'Arial Black',
    //   size: 14,
    // };
    // worksheet.getCell('A1').alignment = {
    //   vertical: 'top',
    //   horizontal: 'center',
    //   wrapText: true,
    // };
    // worksheet.addRow([]);
  };
  const exceljsPostProcess = async ({ worksheet }) => {
    // // add a text after the data
    // worksheet.addRow({}); // Add empty row
  
    // worksheet.addRow(['Those data are for internal use only']);
  };
  
  const excelOptions = {
    exceljsPreProcess,
    exceljsPostProcess,
  };

  function CustomExportButton(props) {
    return (
      <GridToolbarExportContainer {...props}>
       <GridExcelExportMenuItem options={excelOptions}/>
      </GridToolbarExportContainer>
    );
  }

  return (
    <>
      <div
        style={{
          height: 700,
          width: "100%",
          marginTop: "20px",
          background: "#fff",
        }}
      >
        <DataGridPremium
          loading={isLoading}
          columns={columns}
          rows={rows}
          initialState={{
            sorting: {
              sortModel: [{ field: "ReceiptDate", sort: "desc" }],
            },
          }}
          slots={{ toolbar: CustomToolbar }}
          pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
          getDetailPanelHeight={() => 400}
          getDetailPanelContent={getDetailPanelContent}
          sx={{
            "& .MuiDataGrid-detailPanel": {
              overflow: "visible",
            },
            "& .MuiButton-text": { fontSize:  "14px"}
          }}
        />
      </div>
    </>
  );
};

export default memo(InventoryTransactionTable);
