import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";

const columns = [
  {
    field: "CarrierCode",
    headerClassName: "headingbg-color",
    headerName: "CarrierCode",
    width: 200,
  },
  {
    field: "CarrierName",
    headerClassName: "headingbg-color",
    headerName: "CarrierName",
    width: 200,
  },
  {
    field: "ShipDate",
    headerClassName: "headingbg-color",
    headerName: "ShipDate",
    width: 200,
  },
  {
    field: "CustPOnumber",
    headerClassName: "headingbg-color",
    headerName: "CustPOnumber",
    width: 160,
  },
  {
    field: "Customer",
    headerClassName: "headingbg-color",
    headerName: "Customer",
    width: 150,
  },

  {
    field: "TotalQty",
    headerClassName: "headingbg-color",
    headerName: "TotalQty",
    width: 150,
  },
  {
    field: "TotalWeight",
    headerClassName: "headingbg-color",
    headerName: "TotalWeight",
    width: 150,
  },
  {
    field: "TotalCube",
    headerClassName: "headingbg-color",
    headerName: "TotalCube",
    width: 150,
  },
];

const ShipmentReportCarrierWiseTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      carrierCode: "all",
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getRprt_carrierWiseShipmentDtls",
      payload,
      headers
    );
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    CarrierCode: c.CarrierCode,
    CarrierName: c.CarrierName,
    ShipDate: c.ShipDate,
    CustPOnumber: c.CustPOnumber,
    Customer: c.Customer,
    TotalQty: c.TotalQty,
    TotalWeight: c.TotalWeight,
    TotalCube: c.TotalCube,
  }));

  return (
    <div
      style={{
        height: 700,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columns={columns}
        rows={rows}
        sx={{ flex: 1, "& .MuiButton-text": { fontSize:  "14px"} }}
      ></DataGridPremium>
    </div>
  );
};

export default ShipmentReportCarrierWiseTable;
