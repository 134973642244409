import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "../../services/api";
import {
  DataGridPremium,
  useGridApiRef,
  GridToolbar,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridToolbarExportContainer,
  GridExcelExportMenuItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import AsnDetailPanel from "./AsnDetailPanel";
import { Box, Button, FormLabel,  MenuItem,  OutlinedInput, Select, Stack } from "@mui/material";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import Grid from '@mui/material/Grid';
import moment from "moment";

const columns = [
  {
    field: "ReceiptNo",
    headerClassName: "headingbg-color",
    headerName: "ASN Receipt No",
  },
  {
    field: "ReceiptType",
    headerClassName: "headingbg-color",
    headerName: "Receipt Type",
  },
  {
    field: "ReceiptDate",
    headerClassName: "headingbg-color",
    headerName: "Receipt Date",
  },
  {
    field: "totExpectedQty",
    headerClassName: "headingbg-color",
    headerName: "Total Expected Qty",
    width: 200,
  },
  {
    field: "totReceivedQty",
    headerClassName: "headingbg-color",
    headerName: "Total Received Qty",
    width: 200,
  },
  {
    field: "totDamagedQty",
    headerClassName: "headingbg-color",
    headerName: "Total Damaged Qty",
    width: 200,
  },

  {
    field: "ReceiptStatus",
    headerClassName: "headingbg-color",
    headerName: "Receipt Status",
  },
  {
    field: "ExpectedReceiptDate",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Expected Arrival Date",
  },
  {
    field: "WarehouseReference",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Warehouse Reference",
  },
  {
    field: "DateCreated",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Date Created",
  },

  {
    field: "ExternalReceiptNo",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "External Receipt No",
  },
  {
    field: "CarrierReference",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Carrier Reference",
  },
  {
    field: "ClosedDate",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Closed Date",
  },
  {
    field: "TransportationStatus",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Transportation Status",
  },
  {
    field: "TrailerNumber",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Trailer Number",
  },
  {
    field: "ContainerNumber",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Container Number",
  },
];

const AsnTable = (props) => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [asnData, setAsnData] = React.useState([]);
  const [formStatus, setFormStatus] = useState("all")
  const [receivedDate, setReceivedDate] = useState({from_receiptRcvdDt: '', to_receiptRcvdDt:''});
  const [receiptNumber, setReceiptNumber] = useState('');
  const [trailerNumber, setTrailerNumber] = useState('');
  const [itemNum, setItemNum] = useState('');
  const [detailData, setDetailData] = React.useState([]);
  const [clearFormData, setClearFormData] = useState(false);
  const [statusMenu, setStatusMenu] = useState([])

  const rows = asnData?.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    ReceiptNo: c.ReceiptNo,
    ReceiptType: c.ReceiptType,
    ReceiptDate: c.ReceiptDate,
    totExpectedQty: c.totExpectedQty,
    totReceivedQty: c.totReceivedQty,
    totDamagedQty: c.totDamagedQty,
    ReceiptStatus: c.ReceiptStatus,
    ExpectedReceiptDate: c.ExpectedReceiptDate,
    WarehouseReference: c.WarehouseReference,
    DateCreated: c.DateCreated,
    ExternalReceiptNo: c.ExternalReceiptNo,
    CarrierReference: c.CarrierReference,
    ClosedDate: c.ClosedDate,
    TransportationStatus: c.TransportationStatus,
    TrailerNumber: c.TrailerNumber,
    ContainerNumber: c.ContainerNumber
  }));

  const label = receivedDate.from_receiptRcvdDt ? receivedDate.from_receiptRcvdDt.format("MMMM D, YYYY") + " - " + receivedDate.to_receiptRcvdDt.format("MMMM D, YYYY") : '';

  const fetchAsnTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      from_receiptDt: props.fromDate
        ? props.fromDate
        : startDate.format("YYYY-MM-DD"),
      to_receiptDt: props.toDate ? props.toDate : endDate.format("YYYY-MM-DD"),
      from_receiptRcvdDt: receivedDate.from_receiptRcvdDt ? receivedDate.from_receiptRcvdDt : '',
      to_receiptRcvdDt: receivedDate.to_receiptRcvdDt ? receivedDate.to_receiptRcvdDt : '',
      receiptNumber: receiptNumber ? receiptNumber : 'all',
      trailerNumber: trailerNumber ? trailerNumber: 'all',
      itemNum: itemNum ? itemNum : 'all',
      receiptStatus: formStatus ? formStatus :'all',
    };
    const response = await postRequest(
      "user/getRprt_asnReceipts_forExcelExport",
      payload,
      headers
    );
    if(apiRef.current) {
      apiRef.current.setExpandedDetailPanels([])
    }
    
    setClearFormData(false);
    setAsnData(response.data.Payload.Table);
    setDetailData(response.data.Payload.Table1)
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAsnTableData();
  }, [startDate, endDate, userSetting]);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <AsnDetailPanel row={row} data={detailData}/>,
    [asnData]
  );

  const handleCallback = (start, end) => {
    setReceivedDate({from_receiptRcvdDt: start, to_receiptRcvdDt: end})
  };

  const handleStatusChange = (e) => {
    setFormStatus(e.target.value)
  }

  const handleSearch = () => {
    fetchAsnTableData()
  }

  const clearForm = () => {
    setReceivedDate({from_receiptRcvdDt: '', to_receiptRcvdDt:''});
    setReceiptNumber('');
    setTrailerNumber('');
    setItemNum('');
    setFormStatus('all');
    setClearFormData(true);
  }
  useEffect(() => {
    if(clearFormData) {
      fetchAsnTableData()
    }
  }, [clearFormData])
  

  function CustomToolbar(props) {
    return (
      <GridToolbarContainer {...props}>
        <GridToolbarColumnsButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <GridToolbarFilterButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <GridToolbarDensitySelector slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        {/* <GridToolbarExport /> */}
        <CustomExportButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }



  const exceljsPreProcess = async ({ workbook, worksheet }) => {
    // Set document meta data
    workbook.creator = 'MUI-X team';
    workbook.created = new Date();
    worksheet.name = "Inbound"
    
    let worksheetMy = workbook.addWorksheet("Inbound Details");

    
    worksheetMy.state = 'visible';

    worksheetMy.columns = [
      { header: 'Line Item No', key: 'LineItemNo' },
      { header: 'Receipt No', key: 'ReceiptNo' },
      { header: 'Item', key: 'Item' },
      { header: 'Pack', key: 'Pack' },
      { header: 'Expected Qty', key: 'ExpectedQty' },
      { header: 'Received Qty', key: 'ReceivedQty' },
      { header: 'Receipt Status', key: 'ReceiptStatus' },
      { header: 'Receipt Type', key: 'ReceiptType' },
      { header: 'Received Date', key: 'ReceivedDate' },
      { header: 'UOM', key: 'UOM' },
      { header: 'Condition Code', key: 'ConditionCode' },
      { header: 'CUBE', key: 'CUBE' },
      { header: 'Gross Weight', key: 'GrossWeight' },
      { header: 'Net Weight', key: 'NetWeight' },
      { header: 'QC Required', key: 'QCRequired' },
      { header: 'QC Status', key: 'QCStatus' },
    ];

    worksheetMy.addRows(detailData);
  
    // Customize default excel properties
    worksheet.properties.defaultRowHeight = 30;
  
    // Create a custom file header
    // worksheet.mergeCells('A1:C2');
    // worksheet.getCell('A1').value =
    //   'This is an helping document for the MUI-X team.\nPlease refer to the doc for up to date data.';
  
    // worksheet.getCell('A1').border = {
    //   bottom: { style: 'medium', color: { argb: 'FF007FFF' } },
    // };
  
    // worksheet.getCell('A1').font = {
    //   name: 'Arial Black',
    //   size: 14,
    // };
    // worksheet.getCell('A1').alignment = {
    //   vertical: 'top',
    //   horizontal: 'center',
    //   wrapText: true,
    // };
    // worksheet.addRow([]);
  };
  const exceljsPostProcess = async ({ worksheet }) => {
    // // add a text after the data
    // worksheet.addRow({}); // Add empty row
  
    // worksheet.addRow(['Those data are for internal use only']);
  };
  
  const excelOptions = {
    exceljsPreProcess,
    exceljsPostProcess,
  };

  function CustomExportButton(props) {
    return (
      <GridToolbarExportContainer {...props}>
       <GridExcelExportMenuItem options={excelOptions}/>
      </GridToolbarExportContainer>
    );
  }

  const getStatus = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      masterListFor: 'asnStatus'
    };
    const response = await postRequest(
      "user/Get_MasListVals",
      payload,
      headers
    );

    setStatusMenu(response.data.Payload.Table)
  }

  useEffect(() => {
    getStatus();
  }, [])

  return (
    <>
      {!props.isFilterHide && <div>
        <Grid container spacing={2}>
          <Grid item xs= {12} md= {4}>
            <FormLabel htmlFor="received-date" sx={{fontSize:"0.9rem"}}>
              Received Date
            </FormLabel>
            <DateRangePicker
              initialSettings={{
                startDate: receivedDate.from_receiptRcvdDt ? receivedDate.from_receiptRcvdDt.toDate(): moment().toDate(),
                endDate: receivedDate.to_receiptRcvdDt ? receivedDate.to_receiptRcvdDt.toDate() : moment().toDate(),
              }}
              onCallback={handleCallback}
            >
              <div id="reportrange" className="box-with-icon filterCalendar">
                <span>{label}</span>
                <CalendarMonthOutlinedIcon />
              </div>
            </DateRangePicker>
          </Grid>
          <Grid item xs= {12} md= {4}>
            <FormLabel htmlFor="receipt-number" sx={{fontSize:"0.9rem"}}>
              Receipt Number
            </FormLabel>
            <div>
            <OutlinedInput
              name="receipt-number"
              placeholder="Enter Receipt Number"
              fullWidth
              inputProps={{
                style: {
                  height: "0.3rem",
                },
              }}
              onChange={(e) => setReceiptNumber(e.target.value)}
              value={receiptNumber}
            />
            </div>
          </Grid>
          <Grid item xs= {12} md= {4}>
            <FormLabel htmlFor="item-number" sx={{fontSize:"0.9rem"}}>
              Item Number
            </FormLabel>
            <div>
            <OutlinedInput
              name="item-number"
              placeholder="Enter Item Number"
              fullWidth
              inputProps={{
                style: {
                  height: "0.3rem",
                },
              }}
              value={itemNum}
              onChange={(e) => setItemNum(e.target.value)}
            />
            </div>
          </Grid>
          <Grid item xs= {12} md= {4}>
            <FormLabel htmlFor="status" sx={{fontSize:"0.9rem"}}>
              Status
            </FormLabel>
            <div>
            <Select
              SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
              labelId="status"
              id="status"
              value={formStatus}
              onChange={handleStatusChange}
              fullWidth
              inputProps={{
                style: {
                  maxHeight: "0.6rem",
                },
              }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              {statusMenu.map( item => <MenuItem value={item.text}>{item.text}</MenuItem>)}
            </Select>
            </div>
          </Grid>
          <Grid item xs= {12} md= {4}>
            <FormLabel htmlFor="trailer-number" sx={{fontSize:"0.9rem"}}>
              Trailer Number
            </FormLabel>
            <div>
            <OutlinedInput
              name="trailer-number"
              placeholder="Enter Trailer Number"
              fullWidth
              inputProps={{
                style: {
                  height: "0.3rem",
                },
              }}
              value={trailerNumber}
              onChange={(e) => setTrailerNumber(e.target.value)}
            />
            </div>
          </Grid>

         <Grid item xs= {12} md= {12}>
          <Stack direction="row" spacing={2}>
            <Button color="grey"  variant="contained" onClick={clearForm} size="small">Reset</Button>
            <Button variant="contained" onClick={handleSearch} size="small">
              Apply Filter
            </Button>
          </Stack>
          </Grid>

        </Grid>
      </div>}
      <div
        style={{
          height: 600,
          width: "100%",
          marginTop: "20px",
          background: "#fff",
        }}
      >
        <DataGridPremium
          apiRef= {apiRef}
          loading={isLoading}
          columns={columns}
          rows={rows}
          initialState={{
            sorting: {
              sortModel: [{ field: "ReceiptDate", sort: "desc" }],
            },
          }}
          density="compact"
          slots={{ toolbar: CustomToolbar }}
          
          pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
          getDetailPanelHeight={() => 400}
          getDetailPanelContent={getDetailPanelContent}
          sx={{
            "& .MuiDataGrid-detailPanel": {
              overflow: "visible",
            },
            "& .MuiButton-text": { fontSize:  "14px"}
          }}
        />
      </div>
    </>
  );
};

export default AsnTable;
