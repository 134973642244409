import { DataGridPremium, GridExcelExportMenuItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridToolbarFilterButton, GridToolbarQuickFilter, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";
import IntransitDetailPanel from "./IntransitDetailPanel";
import { Box, Button, FormLabel, Grid, MenuItem, OutlinedInput, Select, Stack } from "@mui/material";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

const columns = [
  {
    field: "Container_Number",
    headerClassName: "headingbg-color",
    headerName: "Container Number",
  },
  {
    field: "Eq_type",
    headerClassName: "headingbg-color",
    headerName: "Eq type",
  },
  {
    field: "DO_received",
    headerClassName: "headingbg-color",
    headerName: "DO received",
  },
  {
    field: "Release_Number",
    headerClassName: "headingbg-color",
    headerName: "Release Number",
  },
  {
    field: "Available_or_Ready_date",
    headerClassName: "headingbg-color",
    headerName: "Available or Ready date",
  },
  {
    field: "Port_Appt",
    headerClassName: "headingbg-color",
    headerName: "Port Appt",
  },
  {
    field: "Delivery_Date",
    width: 250,
    headerClassName: "headingbg-color",
    headerName: "Delivery Date",
  },
  {
    field: "Return_date",
    headerClassName: "headingbg-color",
    headerName: "Empty Return Date",
  },

  {
    field: "Status",
    headerClassName: "headingbg-color",
    headerName: "Status",
  },
  // {
  //   field: "Load_No",
  //   headerClassName: "headingbg-color",
  //   headerName: "Load No",
  // },
  // {
  //   field: "Customer",
  //   width: 120,
  //   headerClassName: "headingbg-color",
  //   headerName: "Customer",
  // },
  // {
  //   field: "Vessel",
  //   headerClassName: "headingbg-color",
  //   headerName: "Vessel",
  // },
  // {
  //   field: "Voyage",
  //   headerClassName: "headingbg-color",
  //   headerName: "Voyage",
  // },
  // {
  //   field: "ETA_Date",
  //   headerClassName: "headingbg-color",
  //   headerName: "ETA Date",
  // },
  // {
  //   field: "Hot_Container",
  //   headerClassName: "headingbg-color",
  //   headerName: "Hot Container",
  // },
  // { field: "Notes", headerClassName: "headingbg-color", headerName: "Notes" },
  // {
  //   field: "TrailerType",
  //   headerClassName: "headingbg-color",
  //   headerName: "Trailer Type",
  // },
  // {
  //   field: "Current_Location_Port",
  //   width: 250,
  //   headerClassName: "headingbg-color",
  //   headerName: "Current Location Port",
  // },

  // {
  //   field: "Discharge_Location_Port",
  //   headerClassName: "headingbg-color",
  //   headerName: "Discharge Location Port",
  // },
  // {
  //   field: "Return_loc_with_tracking",
  //   headerClassName: "headingbg-color",
  //   headerName: "Return loc with tracking",
  // },
  // {
  //   field: "Delivery_Location_Address",
  //   headerClassName: "headingbg-color",
  //   headerName: "Delivery Location Address",
  // },
  // { field: "LFD", headerClassName: "headingbg-color", headerName: "LFD" },
  // {
  //   field: "Customs_Release",
  //   headerClassName: "headingbg-color",
  //   headerName: "Customs Release",
  // },
  // {
  //   field: "On_VACIS_Hold",
  //   headerClassName: "headingbg-color",
  //   headerName: "On VACIS Hold",
  // },

  // {
  //   field: "Seal_No",
  //   headerClassName: "headingbg-color",
  //   headerName: "Seal No",
  // },
  // {
  //   field: "Pickup_date",
  //   headerClassName: "headingbg-color",
  //   headerName: "Pickup date",
  // },
  // {
  //   field: "SSL",
  //   width: 250,
  //   headerClassName: "headingbg-color",
  //   headerName: "SSL",
  // },
];

const IntransitTable = () => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const { user, userSetting } = useSelector((state) => state.user);
  const [detailData, setDetailData] = React.useState([]);
  const [availableDate, setAvailableDate] = useState({from_availableDt: moment().subtract(29, "days"), to_availableDt:moment()});
  const [deliveryDate, setDeliveryDate] = useState({from_deliveryDt: '', to_deliveryDt: ''});
  const [returnDate, setReturnDate] = useState({from_emptyReturnDt: '', to_emptyReturnDt:''});
  const [containerNumber, setContainerNumber] = useState('');
  const [containerStatus, setContainerStatus] = useState('all');
  const [clearFormData, setClearFormData] = useState(false);
  const [statusMenu, setStatusMenu] = useState([])

  const availableDatelabel = availableDate.from_availableDt ? availableDate.from_availableDt.format("MMMM D, YYYY") + " - " + availableDate.to_availableDt.format("MMMM D, YYYY") : '';
  const deliveryDatelabel = deliveryDate.from_deliveryDt ? deliveryDate.from_deliveryDt.format("MMMM D, YYYY") + " - " + deliveryDate.to_deliveryDt.format("MMMM D, YYYY") : '';
  const returnDatelabel = returnDate.from_emptyReturnDt ? returnDate.from_emptyReturnDt.format("MMMM D, YYYY") + " - " + returnDate.to_emptyReturnDt.format("MMMM D, YYYY") : '';

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      from_availableDt: availableDate.from_availableDt.format("YYYY-MM-DD"),
      to_availableDt: availableDate.to_availableDt.format("YYYY-MM-DD"),
      from_deliveryDt: deliveryDate.from_deliveryDt ? deliveryDate.from_deliveryDt.format("YYYY-MM-DD") :"",
      to_deliveryDt: deliveryDate.to_deliveryDt ? deliveryDate.to_deliveryDt.format("YYYY-MM-DD") : '',
      from_emptyReturnDt: returnDate.from_emptyReturnDt ? returnDate.from_emptyReturnDt  : '',
      to_emptyReturnDt: returnDate.to_emptyReturnDt ? returnDate.to_emptyReturnDt  : '',
      containerNumber: containerNumber ? containerNumber : "all",
      containerStatus: containerStatus ? containerStatus : "all"
    };
    const response = await postRequest(
      "user/getRprt_containers_forExcelExport",
      payload,
      headers
    );
    if(apiRef.current) {
      apiRef.current.setExpandedDetailPanels([])
    }
    setClearFormData(false);
    setData(response.data.Payload.Table);
    setDetailData(response.data.Payload.Table1)
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const rows = data.map((c, index) => ({
    id: index,
    Load_No: c.Load_No,
    Customer: c.Customer,
    Vessel: c.Vessel,
    Voyage: c.Voyage,
    Container_Number: c.Container_Number,
    Status: c.Status,
    ETA_Date: c.ETA_Date,
    Hot_Container: c.Hot_Container,
    Freight_Paid: c.Freight_Paid,
    Notes: c.Notes,
    Eq_type: c.Eq_type,
    Current_Location_Port: c.Current_Location_Port,
    Discharge_Location_Port: c.Discharge_Location_Portoad_No,
    Return_loc_with_tracking: c.Return_loc_with_tracking,
    Delivery_Location_Address: c.Delivery_Location_Address,
    LFD: c.LFD,
    DO_received: c.DO_received,
    Available_or_Ready_date: c.Available_or_Ready_date,
    Delivery_Date: c.Delivery_Date,
    Customs_Release: c.Customs_Release,
    On_FDA_Hold: c.On_FDA_Hold,
    On_VACIS_Hold: c.Notes,
    Release_Number: c.Release_Number,
    Seal_No: c.Seal_No,
    Pickup_date: c.Pickup_date,
    Return_date: c.Return_date,
    SSL: c.SSL,
    Port_Appt:c.Port_Appt
  }));

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <IntransitDetailPanel row={row} data={detailData}/>,
    [data]
  );

  function CustomToolbar(props) {
    return (
      <GridToolbarContainer {...props}>
        <GridToolbarColumnsButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <GridToolbarFilterButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <GridToolbarDensitySelector slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        {/* <GridToolbarExport /> */}
        <CustomExportButton slotProps={{ button: { sx: {fontSize:"14px"}} }}/>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }



  const exceljsPreProcess = async ({ workbook, worksheet }) => {
    // Set document meta data
    workbook.creator = 'MUI-X team';
    workbook.created = new Date();
    worksheet.name = "In Transit"
    
    let worksheetMy = workbook.addWorksheet("In Transit Details");

    
    worksheetMy.state = 'visible';

    worksheetMy.columns = [
      { header: 'Container Number', key: 'Container_Number' },
      { header: 'Eq type', key: 'Eq_type' },
      { header: 'DO received', key: 'DO_received' },
      { header: 'Release Number', key: 'Release_Number' },
      { header: 'Available or Ready date', key: 'Available_or_Ready_date' },
      { header: 'Port Appt', key: 'Port_Appt' },
      { header: 'Delivery Date', key: 'Delivery_Date' },
      { header: 'Return date', key: 'Return_date' },
      { header: 'Status', key: 'Status' },
    ];

    worksheetMy.addRows(detailData);
  
    // Customize default excel properties
    worksheet.properties.defaultRowHeight = 30;
  
    // Create a custom file header
    // worksheet.mergeCells('A1:C2');
    // worksheet.getCell('A1').value =
    //   'This is an helping document for the MUI-X team.\nPlease refer to the doc for up to date data.';
  
    // worksheet.getCell('A1').border = {
    //   bottom: { style: 'medium', color: { argb: 'FF007FFF' } },
    // };
  
    // worksheet.getCell('A1').font = {
    //   name: 'Arial Black',
    //   size: 14,
    // };
    // worksheet.getCell('A1').alignment = {
    //   vertical: 'top',
    //   horizontal: 'center',
    //   wrapText: true,
    // };
    // worksheet.addRow([]);
  };
  const exceljsPostProcess = async ({ worksheet }) => {
    // // add a text after the data
    // worksheet.addRow({}); // Add empty row
  
    // worksheet.addRow(['Those data are for internal use only']);
  };
  
  const excelOptions = {
    exceljsPreProcess,
    exceljsPostProcess,
  };

  function CustomExportButton(props) {
    return (
      <GridToolbarExportContainer {...props}>
       <GridExcelExportMenuItem options={excelOptions}/>
      </GridToolbarExportContainer>
    );
  }

  const handleAvailableDateCallback = (start, end) => {
    setAvailableDate({from_availableDt: start, to_availableDt: end})
  };

  const handleDeliveryDateCallback = (start, end) => {
    setDeliveryDate({from_deliveryDt: start, to_deliveryDt: end})
  };

  const handleReturnDateCallback = (start, end) => {
    setReturnDate({from_emptyReturnDt: start, to_emptyReturnDt: end})
  };

  const clearForm = () => {
    setAvailableDate({from_availableDt: moment().subtract(29, "days"), to_availableDt:moment()});
    setDeliveryDate({from_deliveryDt: '', to_deliveryDt: ''});
    setReturnDate({from_emptyReturnDt: '', to_emptyReturnDt:''});
    setContainerNumber('');
    setContainerStatus('all');
    setClearFormData(true);
  }
  useEffect(() => {
    if(clearFormData) {
      fetchTableData()
    }
  }, [clearFormData])

  const handleSearch = () => {
    fetchTableData()
  }

  const getStatus = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      masterListFor: 'containerStatus'
    };
    const response = await postRequest(
      "user/Get_MasListVals",
      payload,
      headers
    );

    setStatusMenu(response.data.Payload.Table)
  }

  useEffect(() => {
    getStatus();
  }, [])

  return (
    <>
      <div>
          <Grid container spacing={2}>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="Shipped-date" sx={{fontSize:"0.9rem"}}>
                Available Date
              </FormLabel>
              <DateRangePicker
                initialSettings={{
                  startDate: availableDate.from_availableDt.toDate(),
                  endDate: availableDate.to_availableDt.toDate(),
                }}
                onCallback={handleAvailableDateCallback}
                clearBtn = {true}
              >
                <div id="reportrange" className="box-with-icon filterCalendar">
                  <span>{availableDatelabel}</span>
                  <CalendarMonthOutlinedIcon />
                </div>
              </DateRangePicker>
            </Grid>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="delivery-date" sx={{fontSize:"0.9rem"}}>
                Delivery Date
              </FormLabel>
              <DateRangePicker
                initialSettings={{
                  startDate: deliveryDate.from_deliveryDt ? deliveryDate.from_deliveryDt.toDate() : moment().toDate(),
                  endDate: deliveryDate.to_deliveryDt ? deliveryDate.to_deliveryDt.toDate() : '',
                }}
                onCallback={handleDeliveryDateCallback}
              >
                <div id="reportrange" className="box-with-icon filterCalendar">
                  <span>{deliveryDatelabel}</span>
                  <CalendarMonthOutlinedIcon />
                </div>
              </DateRangePicker>
            </Grid>

            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="return-date" sx={{fontSize:"0.9rem"}}>
                Empty Return Date
              </FormLabel>
              <DateRangePicker
                initialSettings={{
                  startDate: returnDate.from_emptyReturnDt ? returnDate.from_emptyReturnDt.toDate() : moment().toDate(),
                  endDate: returnDate.to_emptyReturnDt ? returnDate.to_emptyReturnDt.toDate(): moment().toDate(),
                }}
                onCallback={handleReturnDateCallback}
              >
                <div id="reportrange" className="box-with-icon filterCalendar">
                  <span>{returnDatelabel}</span>
                  <CalendarMonthOutlinedIcon />
                </div>
              </DateRangePicker>
            </Grid>
            
            <Grid item xs= {12} md= {4} sx={{fontSize:"0.9rem"}}>
              <FormLabel htmlFor="container-number">
                Container Number
              </FormLabel>
              <div>
              <OutlinedInput
                name="container-number"
                placeholder="Enter Container Number"
                fullWidth
                inputProps={{
                  style: {
                    height: "0.3rem",
                  },
                }}
                value={containerNumber}
                onChange={(e) => setContainerNumber(e.target.value)}
              />
              </div>
            </Grid>
            <Grid item xs= {12} md= {4}>
              <FormLabel htmlFor="status" sx={{fontSize:"0.9rem"}}>
                Status
              </FormLabel>
              <div>
              <Select
                SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                labelId="status"
                id="status"
                value={containerStatus}
                onChange={(e) => setContainerStatus(e.target.value)}
                fullWidth
                inputProps={{
                  style: {
                    maxHeight: "0.3rem",
                  },
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                {statusMenu.map( item => <MenuItem value={item.text}>{item.text}</MenuItem>)}
              </Select>
              </div>
            </Grid>
            <Grid item xs= {12} md= {12}>
              <Stack direction="row" spacing={2}>
                <Button color="grey"  variant="contained" onClick={clearForm} size="small">Reset</Button>
                <Button variant="contained" onClick={handleSearch} size="small">
                  Apply Filter
                </Button>
              </Stack>
            </Grid>

          </Grid>
        </div>
      <div
        style={{
          height: 700,
          width: "100%",
          marginTop: "20px",
          background: "#fff",
        }}
      >
        <DataGridPremium
          apiRef={apiRef}
          loading={isLoading}
          slots={{ toolbar: CustomToolbar }}
          columns={columns}
          rows={rows}
          getDetailPanelHeight={() => 400}
          getDetailPanelContent={getDetailPanelContent}
            sx={{
              "& .MuiDataGrid-detailPanel": {
                overflow: "visible",
              },
              "& .MuiButton-text": { fontSize:  "14px"}
            }}
        ></DataGridPremium>
      </div>
    </>
  );
};

export default IntransitTable;
