import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logoigt from "../../assets/logoigt.png";
import "../../css/pages/login.css";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../features/user/userSlice";
import * as CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [loginMsg, setloginMsg] = useState("");
  const [username, setUserName] = useState("");
  const [pwd, setPassword] = useState("");
  const key = CryptoJS.enc.Utf8.parse("bwplAPVp1OH7Xzyr");
  const iv = CryptoJS.enc.Utf8.parse("bwplAPVp1OH7Xzyr");
  const navigate = useNavigate();

  const { loading, loggedIn, user, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const encryptedPwd = CryptoJS.AES.encrypt(pwd, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const password = encryptedPwd.toString();

    dispatch(loginUser({ username: username, password: password }));
  };

  useEffect(() => {
    if (localStorage.getItem("userCredential")) {
      navigate("/dashboard");
    }
  }, [loggedIn]);

  return (
    <Grid className="signin pt-10">
      {loading ? (
        <CircularProgress></CircularProgress>
      ) : (
        <>
          <Grid
            sx={{ borderRadius: "4px" }}
            item
            xs={8}
            md={4}
            component={Paper}
            elevation={3}
          >
            <Box
              sx={{
                margin: "64px 32px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box sx={{ maxWidth: "200px", marginBottom: "20px" }}>
                <img src={logoigt} alt="logo" style={{ width: "100%" }} />
              </Box>
              <Typography
                component="h1"
                variant="h5"
                style={{ color: "#585858" }}
              >
                Sign Into Your Account
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  label="Email Address"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  value={pwd}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ padding: "10px 16px", marginTop: "12px" }}
                >
                  Sign In
                </Button>
              </form>
              {error && <div>
                <p className="loginMsg">{error}</p>
              </div>}
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Login;
